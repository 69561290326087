import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

import { useTranslation } from "react-i18next";
import VideoPlayer from "./VideoPlayer";
import ChessButton from "./ChessButton";

const GameLevelSelectionItem = ({
  onLevelSelected,
  level_type_id,
  name,
  description,
  imagePath,
  videoPath,
  autoPlay,
  win,
  lose,
  draw,
  win_coins,
  lose_coins,
  draw_coins,
  isSelected,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [infoOpen, setInfoOpen] = useState(false);

  const isMobile = window.innerWidth <= 600; // Dynamically check if the screen size is mobile
  const mobileFontSize = "12px";
  const regularFontSize = "18px";
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  function handleSelectLevelClick() {
    onLevelSelected();
  }

  const minWidth = "100px";
  const minHeight = "200px";
  const cardColor = "black";
  return (
    <Card
      onClick={(e) => {
        // Prevent selection if clicking on any interactive elements
        if (e.target !== e.currentTarget) {
          return;
        }
        // If none of these elements were clicked, trigger selection
        onSelect();
      }}
      sx={{
        minWidth: isMobile ? 350 : 700,
        maxWidth: isMobile ? 350 : 700,
        minHeight: isMobile ? 660 : 850,
        maxHeight: isMobile ? 660 : 850,
        borderRadius: "15px",
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
        border: "1px solid black",
        boxShadow: "0 5px 5px black",
        backgroundColor: cardColor,
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <CardMedia
        component="div" // CardMedia needs a valid container for custom content
        sx={{
          height: "auto", // Allow height to adjust dynamically
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // border: "1px solid #ccc",
          // boxShadow: "0 10px 10px black",
          // borderRadius: "15px", // Rounded corners
          padding: 0,
        }}
      >
        <VideoPlayer
          isSelected={isSelected}
          videoSrc={videoPath}
          autoPlay={false}
          loop={false}
          muted
          style={{
            width: isMobile ? "100%" : "100%",
            height: isMobile ? "80%" : "100%",
            borderRadius: "1px",
          }}
        />
      </CardMedia>

      {/* Accordion for Content Section */}
      {level_type_id !== "0" && (
        <Accordion
          expanded={accordionExpanded}
          onChange={(e) => {
            e.stopPropagation();
            setAccordionExpanded(!accordionExpanded);
          }}
          sx={{
            borderRadius: "15px",
            boxShadow: "none",

            minHeight: isMobile ? 580 : 920,
            maxHeight: isMobile ? 780 : 920,
          }}
        >
          <AccordionDetails>
            <Box
              sx={{
                zIndex: 100,
                minHeight: "365px",
              }}
            >
              <div>
                <Typography
                  variant={isMobile ? "h6" : "h4"}
                  sx={{
                    color: "text.primary",
                    fontWeight: "bold",
                    border: "2px solid #ccc",
                    boxShadow: "0 10px 10px black",
                    borderRadius: "15px", // Rounded corners
                  }}
                >
                  {name}
                  <Divider
                    sx={{ bgcolor: "secondary.light", marginTop: "15px" }}
                  />
                </Typography>
              </div>

              {level_type_id > 0 && (
                <Box
                  sx={{
                    marginTop: "0px", // Adjusted margin for better spacing
                    display: "flex",
                    flexDirection: isMobile ? "row" : "row", // Stack on mobile, row on larger screens
                    justifyContent: "center", // Center items horizontally
                    alignItems: "center", // Center items vertically
                    width: "100%",
                    gap: isMobile ? 2 : 4, // Increase spacing on mobile
                    padding: isMobile ? 2 : 4, // Reduce padding for small screens
                    textAlign: "center",
                  }}
                >
                  
                  
                  {/* Hide divider on mobile */}
                  {/* Lose */}
                  <Box
                    sx={{
                      textAlign: "center",
                      width: isMobile ? "90%" : "30%", // Full width on mobile, 30% on desktop
                      minWidth: minWidth, // Limit size to keep things neat
                      minHeight: minHeight, // Limit size to keep things neat
                      //maxWidth: "290px", // Limit size to keep things neat
                      border: "5px solid #ba55d3",
                      boxShadow: "9px 12px 15px black",
                      borderRadius: "8px",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant={isMobile ? "h6" : "h5"}
                      sx={{
                        color: "white",
                        bgcolor: "#ba55d3",
                      }}
                    >
                      {/* <SentimentDissatisfiedRoundedIcon
                        sx={{ fontSize: "inherit", color: "red" }}
                      /> */}
                      {t("Game_level_description_Lose")}
                    </Typography>
                    <Divider sx={{ bgcolor: "red", marginTop: "1px" }} />
                    <Typography
                        variant={isMobile ? "h5" : "h4"}
                        sx={{
                          color: "red",
                          padding: 2,
                          display: 'flex', // Enable flexbox
                          flexDirection: 'column', // Arrange children in a column
                          alignItems: 'center', // Center children horizontally
                        }}
                      >
                        <Box
                          component="img"
                          src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"}
                          alt="Money Icon"
                          sx={{
                            width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
                            marginBottom: '5px', // Add margin to separate image from text
                          }}
                        />
                        {lose}
                      </Typography>
                    <Divider sx={{ bgcolor: "red", marginTop: "1px" }} />
                    <Typography
                        variant={isMobile ? "h5" : "h4"}
                        sx={{
                          color: "red",
                          padding: 1,
                          display: 'flex', // Enable flexbox
                          flexDirection: 'column', // Arrange children in a column
                          alignItems: 'center', // Center children horizontally
                        }}
                      >
                        <Box
                          component="img"
                          src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
                          alt="Money Icon"
                          sx={{
                            width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
                            marginBottom: '5px', // Add margin to separate image from text
                          }}
                        />
                        {lose_coins}
                      </Typography>
                  </Box>
                  {!isMobile && <Divider orientation="vertical" flexItem />}{" "}
                  {/* Win */}
                  <Box
                    sx={{
                      textAlign: "center",
                      width: isMobile ? "90%" : "30%", // Full width on mobile, 30% on desktop
                      minWidth: minWidth, // Limit size to keep things neat
                      minHeight: minHeight, // Limit size to keep things neat
                      //maxWidth: "290px", // Limit size to keep things neat
                      border: "5px solid #808000",
                      boxShadow: "9px 12px 15px black",
                      borderRadius: "8px",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant={isMobile ? "h6" : "h5"}
                      sx={{
                        color: "white",
                        bgcolor: "#808000",
                      }}
                    >
                      {/* <SentimentSatisfiedAltRoundedIcon
                        sx={{ fontSize: "inherit", color: "#FFAB40" }}
                      /> */}
                      {t("Game_level_description_Win")}
                    </Typography>
                    <Divider sx={{ bgcolor: "green", marginTop: "1px" }} />
                    <Typography
                      variant={isMobile ? "h5" : "h4"}
                      sx={{
                        color: "green",
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"}
                        alt="Money Icon"
                        sx={{
                          width: { xs: "1.3rem", sm: "1.5rem" },
                          marginBottom: "5px", // Add margin to separate star from text
                        }}
                      />
                      {win}
                    </Typography>
                    <Divider sx={{ bgcolor: "green", marginTop: "1px" }} />
                    <Typography
                      variant={isMobile ? "h5" : "h4"}
                      sx={{
                        color: "green",
                        padding: 1,
                        display: 'flex', // Enable flexbox
                        flexDirection: 'column', // Arrange children in a column
                        alignItems: 'center', // Center children horizontally
                      }}
                    >
                      <Box
                        component="img"
                        src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
                        alt="Money Icon"
                        sx={{
                          width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
                          marginBottom: '5px', // Add margin to separate image from text
                        }}
                      />
                      {win_coins}
                    </Typography>
                  </Box>
                  {!isMobile && <Divider orientation="vertical" flexItem />}{" "}
                  {/* Hide divider on mobile */}
                  {/* Draw */}
                  <Box
                    sx={{
                      textAlign: "center",
                      width: isMobile ? "90%" : "30%", // Full width on mobile, 30% on desktop
                      minWidth: minWidth, // Limit size to keep things neat
                      minHeight: minHeight, // Limit size to keep things neat
                      //maxWidth: "290px", // Limit size to keep things neat
                      border: "5px solid #4169e1",
                      boxShadow: "9px 12px 15px black",
                      borderRadius: "8px",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant={isMobile ? "h6" : "h5"}
                      sx={{
                        color: "white",
                        bgcolor: "#4169e1",
                      }}
                    >
                  
                      {t("Game_level_description_Draw")}
                    </Typography>
                    <Divider sx={{ bgcolor: "green", marginTop: "1px" }} />
                    <Typography
                        variant={isMobile ? "h5" : "h4"}
                        sx={{
                          color: "blue",
                          padding: 2,
                          display: 'flex', // Enable flexbox
                          flexDirection: 'column', // Arrange children in a column
                          alignItems: 'center', // Center children horizontally
                        }}
                      >
                        <Box
                          component="img"
                          src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"}
                          alt="Money Icon"
                          sx={{
                            width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
                            marginBottom: '5px', // Add margin to separate image from text
                          }}
                        />
                        {draw}
                      </Typography>
                    <Divider sx={{ bgcolor: "blue", marginTop: "1px" }} />
                    <Typography
                        variant={isMobile ? "h5" : "h4"}
                        sx={{
                          color: "blue",
                          padding: 1,
                          display: 'flex', // Enable flexbox
                          flexDirection: 'column', // Arrange children in a column
                          alignItems: 'center', // Center children horizontally
                        }}
                      >
                        <Box
                          component="img"
                          src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
                          alt="Money Icon"
                          sx={{
                            width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
                            marginBottom: '5px', // Add margin to separate image from text
                          }}
                        />
                        {draw_coins}
                      </Typography>
                  </Box>
                </Box>
              )}
            </Box>

            <div
              style={{
                //position: "absolute", // Absolute positioning relative to parent
                marginTop: "0px", // 20px from the bottom of the viewport
                //left: "50%", // Center horizontally
                //transform: "translateX(-50%)", // Adjust for centering
                zIndex: 10, // Ensure it's above other elements
                display: "flex", // Arrange items in a row
                flexDirection: "row", // Keep items in a horizontal line
                alignItems: "center", // Align items vertically
                justifyContent: "center", // Center items horizontally

                //border: "4px solid #ccc",
                //boxShadow: "0 15px 15px black",
                borderRadius: "15px", // Rounded corners
                //padding: 25,
              }}
            >
              <ChessButton
                onPress={handleSelectLevelClick}
                shadowColor="black"
                fontSize={isMobile === true ? mobileFontSize : regularFontSize}
                text={t("Vote")}
                height={isMobile === true ? "50px" : "60px"}
                width={isMobile === true ? "170px" : "220px"}
                icon = {"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743932484/Pictures/vote_1.png" && <img src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743932484/Pictures/vote_1.png"} alt="icon" style={{ width: "30px", height: "30px", marginRight:"10px" }} />}
              ></ChessButton>
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      {level_type_id === "0" && (
        <>
          <Typography
            sx={{
              fontWeight: "bold",
              // marginTop: "15px",
              fontSize: isMobile ? "20px" : "30px",
              textAlign: "center",
              color: "white",
              border: "0px solid #ccc",
              boxShadow: "0 25px 25px black",
              padding: 3,
            }}
          >
            {t("Level_Select")}
          </Typography>
        </>
      )}

      {level_type_id !== "0" && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setInfoOpen(true);
          }}
          sx={{
            position: "absolute",
            top: isMobile ? 224 : 422, // Adjust vertical position
            right: isMobile ? "38px" : "89px",
            color: "black", // Sets the color of the icon
            fontSize: isMobile ? 1 : 12, // Optional: Increase font size for the IconButton
            border: "1px solid #ccc",
            boxShadow: "0 10px 15px white",
            zIndex: 10,
          }}
        >
          <InfoIcon sx={{ fontSize: isMobile ? 18 : 25 }} />{" "}
          {/* Increase the size of the icon */}
        </IconButton>
      )}

      {/* Logo */}
      {level_type_id === "0" && (
        <Box
          sx={{
            position: "absolute",
            top: isMobile ? "250px" : "500px", // Adjust vertical position
            left: "50%", // Align the left edge to the center
            transform: "translateX(-50%)", // Shift the box horizontally by 50% of its width
            width: isMobile ? "270px" : "320px",
            height: isMobile ? "270px" : "320px",
            backgroundImage:  'url("https://res.cloudinary.com/dnlgtek2a/image/upload/v1743426566/Pictures/logo.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: 1,
            zIndex: 10,
            borderRadius: "10px",
            border: "4px solid #ccc",
            boxShadow: "0 15px 15px black",
          }}
        />
      )}

      {/* Popup for More Info */}
      <Dialog open={infoOpen} onClose={() => setInfoOpen(false)}>
        <DialogTitle>{t(name)}</DialogTitle>
        <DialogContent>
          <Typography>{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setInfoOpen(false);
            }}
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default GameLevelSelectionItem;
