import React, { useState, useEffect, useRef, createRef, useCallback, useMemo, useReducer } from "react";
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  useMediaQuery,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";
import ControlPanel from "./ControlPanel";
import { useTranslation } from "react-i18next";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { useParams } from "react-router-dom";
import MoveLog from "./MoveLog";
import ChessButton from "./ChessButton";
import Swal from 'sweetalert2'
// Initial state for reducer
const initialState = {
  fen: "",
  playerColor: "white",
  aiDialogOpen: false,
  fenDialogOpen: false,
  gameOver: false,
  capturedWhite: [],
  capturedBlack: [],
  aiLevel: 3,
  autoAIMoves: false,
  bestMoveGeneral: "",
  showGameHistory: false,
  isCalculating: false,
  customFen: ""
};

// Reducer for game state management
function gameReducer(state, action) {
  switch (action.type) {
    case 'SET_FEN':
      return { ...state, fen: action.payload };
    case 'SET_PLAYER_COLOR':
      return { ...state, playerColor: action.payload };
    case 'TOGGLE_AI_DIALOG':
      return { ...state, aiDialogOpen: action.payload };
    case 'TOGGLE_FEN_DIALOG':
      return { ...state, fenDialogOpen: action.payload };
    case 'SET_CUSTOM_FEN':
      return { ...state, customFen: action.payload };
    case 'SET_GAME_OVER':
      return { ...state, gameOver: action.payload };
    case 'SET_CAPTURED_PIECES':
      return { 
        ...state, 
        capturedWhite: action.payload.white, 
        capturedBlack: action.payload.black 
      };
    case 'SET_AI_LEVEL':
      return { ...state, aiLevel: action.payload };
    case 'TOGGLE_AUTO_AI':
      return { ...state, autoAIMoves: !state.autoAIMoves };
    case 'SET_AUTO_AI':
      return { ...state, autoAIMoves: action.payload };
    case 'SET_BEST_MOVE':
      return { ...state, bestMoveGeneral: action.payload };
    case 'TOGGLE_GAME_HISTORY':
      return { ...state, showGameHistory: action.payload };
    case 'SET_CALCULATING':
      return { ...state, isCalculating: action.payload };
    case 'RESET_GAME':
      return { 
        ...state, 
        fen: action.payload.fen,
        gameOver: false,
        capturedWhite: [],
        capturedBlack: [],
        bestMoveGeneral: ""
      };
    default:
      return state;
  }
}

// Custom hook for responsive design values
const useResponsiveValues = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  
  return {
    boardWidth: isMobile ? 350 : 500,
    fontSize: isMobile ? "11px" : "14px",
    buttonWidth: isMobile ? "70px" : "140px",
    buttonHeight: isMobile ? "80px" : "60px",
    capturedPiecesTop: isMobile ? -18 : 1,
    borderWidth: isMobile ? "5px" : "15px",
    marginTop: isMobile ? "45px" : "30px",
    isMobile
  };
};

// CapturedPieces component
const CapturedPieces = ({ pieces, color, position, isMobile }) => (
  <div className="captured-pieces-container" style={{
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  }}>
    <div style={{
      position: "relative",
      display: "flex",
      top: position === "top" ? (isMobile ? -18 : 1) : 10,
      gap: "5px",
      zIndex: 10,
    }}>
      {pieces && pieces.length > 0 ? (
        pieces.map((piece, index) => (
          piece && (
            <img
              key={index}
              src={`https://res.cloudinary.com/dnlgtek2a/image/upload/v1743416894/Figures/${piece}_${color}.svg`}
              alt={`${color} ${piece}`}
              style={{ width: "30px", height: "30px" }}
            />
          )
        ))
      ) : (
        <span style={{ color: color === "w" ? "white" : "black" }}></span>
      )}
    </div>
  </div>
);

// Main component
const ResponsiveChessPageLearn = ({ updateBackground, setShowAppBar }) => {
  const { invitePlayerColor } = useParams();
  const { t } = useTranslation();
  const game = useMemo(() => new Chess(), []);
  const [gameState, dispatch] = useReducer(gameReducer, {
    ...initialState,
    fen: game.fen(),
    customFen: game.fen()
  });
  
  const {
    fen, 
    playerColor, 
    aiDialogOpen,
    fenDialogOpen,
    customFen, 
    gameOver, 
    capturedWhite, 
    capturedBlack, 
    aiLevel, 
    autoAIMoves, 
    bestMoveGeneral,
    showGameHistory,
    isCalculating
  } = gameState;

  const stockfishRef = useRef(null);
  const responsive = useResponsiveValues();

  // Local storage management
  const [moveHistory, setMoveHistoryState] = useState([{ fen: game.fen(), move: null }]);
  const [currentMoveIndex, setCurrentMoveIndexState] = useState(0);

  const ref = createRef(null);
  const [, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  // Sleep function for delays
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  // Initialize stockfish and set up background
  useEffect(() => {
    const isLocalhost = window.location.hostname === "localhost";

    stockfishRef.current = new Worker(
      isLocalhost
        ? `${process.env.PUBLIC_URL}/stockfish.wasm.js`
        : `${process.env.PUBLIC_URL}/stockfish.wasm.js`//"https://lichess1.org/stockfish/stockfish.wasm.js"
    );

    stockfishRef.current.postMessage("uci");     // ✅ INIT
    stockfishRef.current.postMessage("isready"); // ✅ Wait for readiness

    updateBackground({ image: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743895176/Pictures/scroll.jpg" });

    stockfishRef.current.onmessage = (event) => {
      const message = event.data;
      if (message.startsWith("bestmove")) {
        const aiMove = message.split(" ")[1];
        dispatch({ type: 'SET_BEST_MOVE', payload: aiMove });
        
        // Auto-execute AI move if enabled and it's AI's turn
        if (autoAIMoves && game.turn() !== playerColor[0]) {
          executeBestMove(aiMove);
        }
      }
    };

    // Set player color from URL if provided
    if (invitePlayerColor) {
      dispatch({ type: 'SET_PLAYER_COLOR', payload: invitePlayerColor });
    }

    return () => {
      if (stockfishRef.current) stockfishRef.current.terminate();
    };
  }, [autoAIMoves, playerColor, invitePlayerColor, updateBackground]);

  // Initialize move history from localStorage
  useEffect(() => {
    if (localStorage.getItem("moveHistory")) {
      setMoveHistory([{ fen: game.fen(), move: null }]);
    }
    if (localStorage.getItem("currentMoveIndex")) {
      setCurrentMoveIndex(0);
    }

    const history = getMoveHistory();
    const index = getCurrentMoveIndex();
    
    dispatch({ type: 'SET_FEN', payload: history[index].fen });
    game.load(history[index].fen);
    updateCapturedPieces();
  }, []);

  // Local storage helper functions
  const getMoveHistory = useCallback(() => {
    return JSON.parse(localStorage.getItem("moveHistory")) || moveHistory;
  }, [moveHistory]);
  
  const setMoveHistory = (history) => {
    localStorage.setItem("moveHistory", JSON.stringify(history));
    setMoveHistoryState(history);
  };
  
  const getCurrentMoveIndex = useCallback(() => {
    return parseInt(localStorage.getItem("currentMoveIndex"), 10) || currentMoveIndex;
  }, [currentMoveIndex]);
  
  const setCurrentMoveIndex = (index) => {
    localStorage.setItem("currentMoveIndex", index);
    setCurrentMoveIndexState(index);
  };

  // Calculate captured pieces
  const updateCapturedPieces = useCallback(() => {
    const initialCounts = {
      w: { p: 8, r: 2, n: 2, b: 2, q: 1, k: 1 },
      b: { p: 8, r: 2, n: 2, b: 2, q: 1, k: 1 },
    };

    const currentCounts = {
      w: { p: 0, r: 0, n: 0, b: 0, q: 0, k: 0 },
      b: { p: 0, r: 0, n: 0, b: 0, q: 0, k: 0 },
    };

    // Count pieces currently on the board
    game.board().flat().forEach((square) => {
      if (square) {
        currentCounts[square.color][square.type]++;
      }
    });

    const newWhiteCaptured = [];
    const newBlackCaptured = [];

    // Importance mapping for sorting pieces (lower is more important)
    const pieceImportance = { k: 1, q: 2, r: 3, b: 4, n: 5, p: 6 };

    // Calculate captured pieces
    Object.keys(initialCounts.w).forEach((piece) => {
      const missingWhitePieces = initialCounts.w[piece] - currentCounts.w[piece];
      const missingBlackPieces = initialCounts.b[piece] - currentCounts.b[piece];

      if (missingWhitePieces > 0)
        newBlackCaptured.push(...Array(missingWhitePieces).fill(piece));
      if (missingBlackPieces > 0)
        newWhiteCaptured.push(...Array(missingBlackPieces).fill(piece));
    });

    // Sort captured pieces based on their importance
    const sortedWhiteCaptured = newWhiteCaptured.sort(
      (a, b) => pieceImportance[a] - pieceImportance[b]
    );
    const sortedBlackCaptured = newBlackCaptured.sort(
      (a, b) => pieceImportance[a] - pieceImportance[b]
    );

    // Update state with sorted captured pieces
    dispatch({ 
      type: 'SET_CAPTURED_PIECES', 
      payload: { 
        white: sortedWhiteCaptured, 
        black: sortedBlackCaptured 
      } 
    });
  }, [game]);

  // Screenshot functionality
  const download = (image, { name = "chess-position", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  // Stockfish analysis
  const analyzeBestMoves = useCallback(() => {
    if (stockfishRef.current) {
      stockfishRef.current.postMessage(`position fen ${game.fen()}`);
      stockfishRef.current.postMessage(`go depth ${aiLevel}`);
    }
  }, [game, aiLevel]);

  // Game over handler
  const handleGameOver = useCallback(() => {
    dispatch({ type: 'SET_GAME_OVER', payload: true });
  }, []);

  // Apply custom FEN
  const applyCustomFen = useCallback(() => {
    try {
      game.load(customFen);
      
      dispatch({ type: 'SET_FEN', payload: game.fen() });
      setMoveHistory([{ fen: game.fen(), move: null }]);
      setCurrentMoveIndex(0);
      
      updateCapturedPieces();
      dispatch({ type: 'TOGGLE_FEN_DIALOG', payload: false });
      
      // Check if game is already over
      if (game.isGameOver()) {
        handleGameOver();
      } else {
        analyzeBestMoves();
      }
          
      Swal.fire({
        title: t("FEN position applied successfully"),
        icon: "success",
        draggable: true,
        confirmButtonText: t("OK"),
      });
    } catch (error) {
      
      Swal.fire({
        title: t("Invalid FEN position"),
        icon: "error",
        draggable: true,
        confirmButtonText: t("OK"),
      });
    }
  }, [customFen, game, updateCapturedPieces, handleGameOver, analyzeBestMoves, t]);

  // Handle chess move
  const handleMove = useCallback((sourceSquare, targetSquare) => {
    let move;
    try {
      move = game.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q", // Always promote to queen for simplicity
      });
      
      if (!move) return false;
    } catch (error) {
      return false;
    }

    // Determine move notation (capture or regular move)
    let space = "-";
    if (move.flags === "c") {
      space = "x";
    }

    // Update move history
    const newHistory = [
      ...getMoveHistory().slice(0, getCurrentMoveIndex() + 1),
      { fen: game.fen(), move: move.from + space + move.to, color: move.color },
    ];
    setMoveHistory(newHistory);
    setCurrentMoveIndex(newHistory.length - 1);

    // Update UI state
    dispatch({ type: 'SET_FEN', payload: game.fen() });
    updateCapturedPieces();

    // Check for game over or analyze next move
    if (game.isGameOver()) {
      handleGameOver();
    } else if (stockfishRef.current) {
      analyzeBestMoves();
    }

    return true;
  }, [game, getMoveHistory, getCurrentMoveIndex, updateCapturedPieces, handleGameOver, analyzeBestMoves]);

  // Execute best move
  const executeBestMove = useCallback(async (aiMove) => {
    dispatch({ type: 'SET_CALCULATING', payload: true });
    
    try {
      await sleep(1000);
      
      let moveToExecute = aiMove || bestMoveGeneral;
      
      // Default opening move if no best move available
      if (!moveToExecute) {
        const index = getCurrentMoveIndex();
        if (index === 0) {
          moveToExecute = "e2e4"; // Standard opening
        }
      }
      
      if (moveToExecute) {
        const sourceSquare = moveToExecute.substring(0, 2);
        const targetSquare = moveToExecute.substring(2, 4);
        handleMove(sourceSquare, targetSquare);
      }
    } catch (error) {
      console.error("Error executing best move:", error);
      
      Swal.fire({
        title: t("Error"),
        text: t("Error executing move"),
        icon: 'error',
        draggable: true,
        confirmButtonText: t("OK"),
      })
  
    } finally {
      dispatch({ type: 'SET_CALCULATING', payload: false });
    }
  }, [bestMoveGeneral, getCurrentMoveIndex, handleMove, t]);

  // Check game status
  const checkGameStatus = useCallback(() => {
    let status = "in_progress";
    
    if (game.isCheckmate()) {
      status = game.turn() === "w" ? "black_won_by_mat_white" : "white_won_by_mat_black";
    } else if (game.isStalemate()) {
      status = "stalemate";
    } else if (game.isThreefoldRepetition()) {
      status = "threefold_repetition";
    } else if (game.isInsufficientMaterial()) {
      status = "insufficient_material";
    } else if (game.isCheck()) {
      status = "check";
    } else if (game.isDraw()) {
      status = "draw";
    }

    return status;
  }, [game]);

  // Game control functions
  const restartGame = useCallback(() => {
    game.reset();
    
    dispatch({ 
      type: 'RESET_GAME', 
      payload: { fen: game.fen() } 
    });
    
    setMoveHistory([{ fen: game.fen(), move: null }]);
    setCurrentMoveIndex(0);
    localStorage.removeItem("moveHistory");
    localStorage.removeItem("currentMoveIndex");
  }, [game]);

  const togglePlayerColor = useCallback(() => {
    const newColor = playerColor === "white" ? "black" : "white";
    dispatch({ type: 'SET_PLAYER_COLOR', payload: newColor });
    analyzeBestMoves();
  }, [playerColor, analyzeBestMoves]);

  const safeOpenDialog = (action) => {
    setTimeout(() => {
      action();
    }, 150); // adjust if needed
  };

  // Memoized UI components
  const chessboardComponent = useMemo(() => (
    <div ref={ref}>
      <div style={{
        justifyContent: "center",
        alignItems: "center",
        border: `${responsive.borderWidth} solid #ccc`,
        borderRadius: "15px",
        boxShadow: "0 25px 25px black",
        backgroundColor: "black",
        padding: "5px",
        position: "relative",
        zIndex: 10,
        marginTop: responsive.isMobile ? "-25px" : "0px",
      }}>
        <Chessboard
          position={fen}
          onPieceDrop={handleMove}
          boardOrientation={playerColor}
          boardWidth={responsive.boardWidth}
          ariaLabel={t("Chess board")}
        />
      </div>
    </div>
  ), [fen, handleMove, playerColor, responsive, ref, t]);

  const controlButtons = useMemo(() => (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      border: `${responsive.borderWidth}  #4a707d`,
      borderRadius: "15px",
      boxShadow: "0 10px 25px black",
      padding: "10px",
      position: "relative",
      zIndex: 10,
      marginTop: responsive.marginTop,
      gap: "2px",
    }}>
      <ChessButton
        text={t("Best Move")}
        onPress={() => executeBestMove()}
        fontSize={responsive.fontSize}
        width={responsive.buttonWidth}
        height={responsive.buttonHeight}
        disabled={isCalculating}
      />
      
      <ChessButton
        text={t("Switch color")}
        onPress={togglePlayerColor}
        fontSize={responsive.fontSize}
        width={responsive.buttonWidth}
        height={responsive.buttonHeight}
      />
      
      <ChessButton
        text={`${t("AI_skill")}: ${autoAIMoves ? `${t("On")}` : `${t("Off")}`}. ${t("Level")}: ${aiLevel}`}
        onPress={() => dispatch({ type: 'TOGGLE_AI_DIALOG', payload: true })}
        fontSize={responsive.fontSize}
        width={responsive.buttonWidth}
        height={responsive.buttonHeight}
      />
      
      <ChessButton 
        text={t("Set Position")} 
              
        onPress={() => safeOpenDialog(() =>
          dispatch({ type: 'TOGGLE_FEN_DIALOG', payload: true })
        )}
        fontSize={responsive.fontSize}
        width={responsive.buttonWidth}
        height={responsive.buttonHeight} 
      />

      <ChessButton 
        text={t("Restart Game")} 
        onPress={restartGame}
        primaryColor="red" 
        hoverColor="red" 
        fontColor="white"
        fontSize={responsive.fontSize}
        width={responsive.buttonWidth}
        height={responsive.buttonHeight} 
      />
    </div>
  ), [
    t, executeBestMove, togglePlayerColor, aiLevel, 
    restartGame, responsive, isCalculating, autoAIMoves
  ]);

  const aiLevelDialog = useMemo(() => (
    <Dialog 
      open={aiDialogOpen} 
      onClose={() => dispatch({ type: 'TOGGLE_AI_DIALOG', payload: false })}
      aria-labelledby="ai-level-dialog-title"
      sx={{
        //position: "sticky", // Ensure it stays within the layout flow
        zIndex: 2000, // High zIndex to ensure it's above other elements
       
      }}
    >
      <DialogTitle id="ai-level-dialog-title">{t("Select AI Level")}</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          flexWrap="wrap"
          alignItems="center"
        >
          {/* Auto AI Move Toggle */}
          <FormControlLabel
            control={
              <Switch
                checked={autoAIMoves}
                onChange={() => dispatch({ type: 'TOGGLE_AUTO_AI' })}
                name="autoAIMoves"
                color="primary"
              />
            }
            label={t("Auto AI Move")}
            style={{ marginBottom: '20px' }}
          />

          {[
            { title: t("Beginner Levels"), levels: [1, 2, 3] },
            { title: t("Intermediate Levels"), levels: [4, 5, 6] },
            { title: t("Advanced Levels"), levels: [7, 8, 9] },
            { title: t("Expert Levels"), levels: [10, 11, 12] },
            { title: t("Grandmaster Levels"), levels: [13, 14, 15] }
          ].map(group => (
            <React.Fragment key={group.title}>
              <Typography variant="h6">{t(group.title)}</Typography>
              <Stack direction="row" spacing={1} justifyContent="center">
                {group.levels.map(level => (
                  <Button
                    key={level}
                    variant="outlined"
                    style={{
                      backgroundColor: aiLevel === level ? "#4CAF50" : "",
                    }}
                    onClick={() => {
                      dispatch({ type: 'SET_AI_LEVEL', payload: level });
                      //dispatch({ type: 'TOGGLE_AI_DIALOG', payload: false });
                    }}
                  >
                    {level}
                  </Button>
                ))}
              </Stack>
            </React.Fragment>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch({ type: 'TOGGLE_AI_DIALOG', payload: false })}>
          {t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  ), [aiDialogOpen, aiLevel, autoAIMoves, t]);

  const fenDialog = useMemo(() => (
    <Dialog 
   
      open={fenDialogOpen} 
      onClose={() => dispatch({ type: 'TOGGLE_FEN_DIALOG', payload: false })}
      aria-labelledby="fen-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="fen-dialog-title">{t("Set Starting Position")}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" style={{ marginBottom: '10px' }}>
          {t("Enter a valid FEN (Forsyth-Edwards Notation) string to set a custom board position")}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="fen"
          label={t("FEN String")}
          type="text"
          fullWidth
          variant="outlined"
          value={customFen}
          onChange={(e) => dispatch({ type: 'SET_CUSTOM_FEN', payload: e.target.value })}
          helperText={t("Example: rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch({ type: 'TOGGLE_FEN_DIALOG', payload: false })}>
          {t("Cancel")}
        </Button>
        <Button onClick={applyCustomFen} color="primary" variant="contained">
          {t("Apply Position")}
        </Button>
      </DialogActions>
    </Dialog>
  ), [fenDialogOpen, customFen, applyCustomFen, t]);

  // Render the component
  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 auto",
        position: "relative",
        paddingBottom: "20px",
        width: `${responsive.boardWidth}px`,
      }}>
        <div style={{
          marginTop: responsive.isMobile ? "5px" : "5px",
          marginBottom: responsive.isMobile ? "22px" : "2px",
        }}></div>

        {/* Captured Pieces - Top */}
        {!gameOver && (
          <div>
            {playerColor === "black"
              ? <CapturedPieces pieces={capturedWhite} color="b" position="top" isMobile={responsive.isMobile} />
              : <CapturedPieces pieces={capturedBlack} color="w" position="top" isMobile={responsive.isMobile} />
            }
          </div>
        )}

        <Stack direction="row" spacing={2}>
          {/* Chessboard */}
          {chessboardComponent}
          
          {/* Game History (Desktop) */}
          {!responsive.isMobile && showGameHistory && (
            <MoveLog
              isMobileView={false}
              t={t}
              getMoveHistory={getMoveHistory}
              getCurrentMoveIndex={getCurrentMoveIndex}
              setCurrentMoveIndex={setCurrentMoveIndex}
              game={game}
              setFen={(newFen) => dispatch({ type: 'SET_FEN', payload: newFen })}
              updateCapturedPieces={updateCapturedPieces}
              disableClick={false}
              onClose={() => dispatch({ type: 'TOGGLE_GAME_HISTORY', payload: false })}
            />
          )}
        </Stack>

        <Stack direction="column" spacing={responsive.isMobile ? -2 : 0}>
          {/* Captured Pieces - Bottom */}
          {!gameOver && (
            <div>
              {playerColor === "black"
                ? <CapturedPieces pieces={capturedBlack} color="w" position="bottom" isMobile={responsive.isMobile} />
                : <CapturedPieces pieces={capturedWhite} color="b" position="bottom" isMobile={responsive.isMobile} />
              }
            </div>
          )}

          <div style={{
            marginTop: responsive.isMobile ? "2px" : "-20px",
            marginBottom: responsive.isMobile ? "-50px" : "2px",
          }}></div>
        </Stack>

        {/* Control Buttons */}
        {controlButtons}

        {/* AI Level Dialog */}
        {aiLevelDialog}

        {/* FEN Configuration Dialog */}
        {fenDialog}

        {/* Control Panel */}
        <div style={{
          maxWidth: `${responsive.boardWidth}px`,
          marginTop: "18px",
          marginLeft: "auto",
          marginRight: "auto",
        }}>
          <ControlPanel
            boardWidth={responsive.boardWidth}
            playerColorFromServer={playerColor}
            gameIdFromServer={null}
            onCaptureBoard={downloadScreenshot}
            setIsViewer={false}
            gameOver={gameOver}
            onAccordionChange={(isExpanded) => {}}
            onGameHistoryChange={(isOpen) => dispatch({ type: 'TOGGLE_GAME_HISTORY', payload: isOpen })}
            getMoveHistory={getMoveHistory}
            aiLevel={aiLevel}
            gameInfo={null}
            learnMode={true}
            gameStatus={checkGameStatus()}
          />
        </div>

        {/* Move Log (Mobile) */}
        {responsive.isMobile && showGameHistory && (
          <div>
            <MoveLog
              isMobileView={true}
              t={t}
              getMoveHistory={getMoveHistory}
              getCurrentMoveIndex={getCurrentMoveIndex}
              setCurrentMoveIndex={setCurrentMoveIndex}
              game={game}
              setFen={(newFen) => dispatch({ type: 'SET_FEN', payload: newFen })}
              updateCapturedPieces={updateCapturedPieces}
              disableClick={false}
              onClose={() => dispatch({ type: 'TOGGLE_GAME_HISTORY', payload: false })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResponsiveChessPageLearn;