import React, { useEffect, useState, useRef } from "react";
import {
  Grid2 as Grid,
  Card,
  CardContent,
  Typography,
 
  Box,
  Divider,
} from "@mui/material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { LottieAnimation } from "../lottie/LottieAnimation.js";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import {
  sendMessage,
  addListener,
  removeListener,
} from "../services/websocketManager";

import { useTranslation } from "react-i18next";
import ChessButton from "./ChessButton";
import Swal from 'sweetalert2'
import { GENERAL } from "../general/General";

const PurchaseCoins = ({ onClose, userData}) => {
  const { t } = useTranslation();
  const [coinPackages, setCoinPackages] = useState([]); // Store coin packages from backend
  
  const [clientToken, setClientToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedPackageType, setSelectedPackageType] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const paymentSectionRef = useRef(null);

  const gridBackGraundPackage = "#e0e0e0";
  const gridBackGraundSubscription = "#e0e0e0";
  const cardBackGroundPackage = "white";
  const cardBackGroundSubscription = "white";
  const cardBackGroundPackageSelected = "#ffab91";
  const cardBackGroundSubscriptionSelected = "#ffab91";

  useEffect(() => {
   if(userData){
    if(!userData.email && !userData.socialUser){
      Swal.fire({
        title: t("Please log in to continue with your coin purchase"),
        icon: "warning",
        draggable: true,
        confirmButtonText: t("OK"),
      });
      onClose();
    }
   }
   else{
    Swal.fire({
      title: t("Please log in to continue with your coin purchase"),
      icon: "warning",
      draggable: true,
      confirmButtonText: t("OK"),
    });
    onClose();
   }
  }, [onClose, t, userData]);

  useEffect(() => {
    const handleWebSocketEvent = (event) => {
      if (event.type === "message") {
        if (event.message.messageType === "send_token_bt") {
          setClientToken(event.message.payment.token);
          setLoading(false);
        } else if (event.message.messageType === "purchase_ok_bt") {
          setLoading(false);
          
          Swal.fire({
            title: t("Congratulation"),
            icon: "success",
            draggable: true,
            confirmButtonText: t("OK"),
          });
          onClose();
        } else if (event.message.messageType === "purchase_fail_bt") {
          setLoading(false);
          
          Swal.fire({
            title: t("Payment failed. Please try again..."),
            icon: "error",
            draggable: true,
            confirmButtonText: t("OK"),
          });
        } else if (event.message.messageType === "coin_packages") {
          setCoinPackages(event.message.coinsPackages || []);
          setLoading(false);
          sessionStorage.setItem("coinPackagesLoaded", "true");
        }
      }
    };

    addListener(handleWebSocketEvent);

    let generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "coin_packages";
    generalMessage.game = null;
    generalMessage.gameInfo = null;
    sendMessage(generalMessage);

    generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "get_token_bt";
    generalMessage.game = null;
    generalMessage.gameInfo = null;
    sendMessage(generalMessage);

    return () => {
      removeListener(handleWebSocketEvent);
    };
  }, [onClose, t]);

  const handlePackageSelection = (planId, packageType, price) => {
    setSelectedPlanId(planId);
    setSelectedPackageType(packageType);
    setSelectedPrice(price);
    // Scroll down to the payment section when a package is selected
    setTimeout(() => {
      if (paymentSectionRef.current) {
        paymentSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const handlePaymentSuccess = (transactionId) => {
    setLoading(true);
    const generalMessage = { ...generalMessageTemplate };
    generalMessage.payment.productType = selectedPackageType;
    generalMessage.payment.planId = selectedPlanId;
    generalMessage.payment.amount = selectedPrice;
    generalMessage.payment.transactionId = transactionId;
    generalMessage.messageType = "checkout_bt";
    sendMessage(generalMessage);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LottieAnimation
          path={"https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743417798/Lottie/heart.json"}
          width="70px"
          autoplay
          loop
        />
      </Box>
    );
  }

  const formatCoinsWithImage = (coins) => {
    const coinsStr = coins.toString(); // Convert to string
    const zeroesMatch = coinsStr.match(/0+$/); // Find trailing zeros

    if (zeroesMatch) {
      const numPart = coinsStr.slice(0, -zeroesMatch[0].length); // Get the number part
      const zeroesArray = Array.from(zeroesMatch[0]); // Convert zeros to array

      return (
        <>
          {numPart}
          {zeroesArray.map((_, index) => (
            <Box
              key={index}
              component="img"
              src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
              alt="Coin Icon"
              sx={{
                width: { xs: "0.9rem", sm: "1.7rem" }, // Responsive sizing
                verticalAlign: "middle",
              }}
            />
          ))}
        </>
      );
    }

    return coinsStr; // If no trailing zeros, return as is
  };

  return (
    <Box sx={{ marginTop: "20px", textAlign: "center" }}>
      {/* Coin Packages Section */}
      <Box
        sx={{
          backgroundColor: gridBackGraundPackage,
          border: "1px solid #ccc",
          padding: "8px",
          borderRadius: "10px",
          
          position: "relative",
          backgroundImage: GENERAL.BACKGROUND,
          backgroundSize: "cover", // Ensures the image covers the entire card
          backgroundPosition: "center", // Centers the background image
        }}
      >
        <Typography variant="h6" sx={{ marginTop: "10px", fontWeight: "bold", color: "white" }}>
          {t("Buy a package of coins instantly and use them anytime")}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {coinPackages
            .filter((pkg) => pkg.packageType === "package")
            .map((pkg, index) => (
              <Grid item key={index} xs={6} sm={3}>
                <Card
                  sx={{
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "10px",
                    marginBottom: "5px",
                    borderRadius: "15px",
                    border:
                      selectedPlanId === pkg.planId
                        ? "1px solid black"
                        : "1px solid black",
                    backgroundColor:
                      selectedPlanId === pkg.planId
                        ? cardBackGroundPackageSelected
                        : cardBackGroundPackage,
                    cursor: "pointer",
                    minWidth: "160px",
                    maxWidth: "160px",
                    minHeight: "280px",
                    maxHeight: "280px",
                  }}
                  onClick={() =>
                    handlePackageSelection(pkg.planId, "package", pkg.price)
                  }
                >
                  <CardContent>
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      {pkg.name}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "gold",
                          fontSize: "2.6rem",
                        }}
                      >
                        {formatCoinsWithImage(pkg.coins)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: "relative", // Ensures it's positioned relative to the card
                       
                        
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "20px",
                        
                      }}
                    >
                      <Typography variant="h6" color="text.primary">
                        ${Number(pkg.price).toFixed(2)}
                      </Typography>

                      <Divider
                        sx={{
                          width: "80%",
                          margin: "20px auto",
                          color: "black",
                        }}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ChessButton
                          text={t("Buy")}
                          size="small"
                          primaryColor="gold"
                          hoverColor="gold"
                          fontColor="black"
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>

      {/* Subscription Plans Section */}
      <Box
        sx={{
          marginTop: "20px",
          backgroundColor: gridBackGraundSubscription,
          border: "1px solid #ccc",
          padding: "8px",
          borderRadius: "10px",
          
          position: "relative",
          backgroundImage: GENERAL.BACKGROUND,
          backgroundSize: "cover", // Ensures the image covers the entire card
          backgroundPosition: "center", // Centers the background image
        }}
      >
        <Typography variant="h6" sx={{ marginTop: "30px", fontWeight: "bold", color: "white"  }}>
          {t("Subscribe to receive coins automatically every month")}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {coinPackages
            .filter((pkg) => pkg.packageType === "subscription")
            .map((pkg, index) => (
              <Grid item key={index} xs={6} sm={3}>
                <Card
                  sx={{
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "10px",
                    marginBottom: "5px",
                    borderRadius: "15px",
                    border:
                      selectedPlanId === pkg.planId
                        ? "1px solid black"
                        : "1px solid black",
                    backgroundColor:
                      selectedPlanId === pkg.planId
                        ? cardBackGroundSubscriptionSelected
                        : cardBackGroundSubscription,
                    cursor: "pointer",
                    minWidth: "150px",
                    maxWidth: "160px",
                    minHeight: "280px",
                    maxHeight: "280px",
                  }}
                  onClick={() =>
                    handlePackageSelection(
                      pkg.planId,
                      "subscription",
                      pkg.price
                    )
                  }
                >
                  <CardContent>
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      {pkg.name}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "gold",
                          fontSize: "2.6rem",
                        }}
                      >
                        {formatCoinsWithImage(pkg.coins)}
                      </Typography>
                    </Box>

                    <Typography variant="h6" color="text.secondary">
                      ${Number(pkg.price).toFixed(2)} /{t("month")}
                    </Typography>

                    <Divider
                        sx={{
                          width: "80%",
                          margin: "5px auto",
                          color: "black",
                        }}
                      />


                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ChessButton
                        text={t("Subscribe")}
                        size="medium"
                        primaryColor="gold"
                        hoverColor="gold"
                        fontColor="black"
                      ></ChessButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>

      {/* Payment Section */}
      {clientToken && selectedPlanId && (
        <Box ref={paymentSectionRef} sx={{ marginTop: "20px" }}>
          <PayPalScriptProvider
            options={{
              "client-id":
                "AWwX3mwlz7F4u5RMDHjunCCEhoTLK25rOGXtNKw0DgcpsME9p4evZGRy13FDvxAHoJJ9HlovV9vWTy4U",
              components: "buttons",
              "data-client-token": clientToken,
            }}
          >
            <PayPalButtons
              createOrder={(data, actions) =>
                actions.order.create({
                  purchase_units: [{ amount: { value: selectedPrice } }],
                })
              }
              onApprove={(data, actions) =>
                actions.order
                  .capture()
                  .then((details) => handlePaymentSuccess(details.id))
              }
              onError={(error) => console.error("PayPal Order Error:", error)}
            />
          </PayPalScriptProvider>
        </Box>
      )}
    </Box>
  );
};

export default PurchaseCoins;
