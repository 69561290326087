import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import i18n from "../services/i18n";
import { useTranslation } from "react-i18next";
import OAuthSignInPage from "./OAuthSignInPage";
import Profile from "./Profile";
import { sendMessage, resetWebSocket } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import { useWebSocketManager } from "../services/useWebSocketManager";
import { addListener, removeListener } from "../services/websocketManager";
import { useUser } from "../authMethods/UserContext";
import Divider from "@mui/material/Divider";
import NotEnoughCoinsDialog from "./NotEnoughCoinsDialog";

const AppVersion = "v1.1.2"; //////////////////////////////////////////////////////////////////////

const languageOptions = [
  {
    code: "en",
    name: "English",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405788/Pictures/ciqsflhvjmllwogcd2fg.svg",
  },
  {
    code: "es",
    name: "Español",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405804/Pictures/inkvrpfdyiaabnctm8ed.svg",
  },
  {
    code: "fr",
    name: "Français",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405800/Pictures/v4eywrpsipssvo3hfwnl.svg",
  },
  {
    code: "de",
    name: "Deutsch",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405799/Pictures/cid5fq7hgggulif5wrrc.svg",
  },
  {
    code: "zh",
    name: "中文",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405797/Pictures/mk2cirsagiqs6e7b9tte.svg",
  },
  {
    code: "ru",
    name: "Русский",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405802/Pictures/iu2rtgtj5sjv6l4u4tvo.svg",
  },
  {
    code: "ja",
    name: "日本語",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405795/Pictures/lwvac5ho5pxezefq7vq6.svg",
  },
  {
    code: "pt",
    name: "Português",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405793/Pictures/eewedmumrzqkfcsfluzv.svg",
  },
  {
    code: "ar",
    name: "العربية",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405790/Pictures/xrtnkp0iobvqsgnll9db.svg",
  },
  {
    code: "hi",
    name: "हिन्दी",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405792/Pictures/solvuww6nbm1mphtdosb.svg",
  },
];

const pages = [
  {
    name: "Welcome",
    route: "/welcome",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "3px solid white",
          backgroundColor: "#ffefd5",
        }}
      >
        <img
          src="https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405611/Pictures/wtrizvebvhltsfhe3n3z.png"
          alt="welcome Icon"
          style={{ width: "44px", height: "44px" }}
        />
      </Box>
    ),
  },
  {
    name: "Play",
    route: "/main",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //border: "2px solid rgba(221, 155, 55, 0.14)",

          backgroundColor: "#ffefd5",
          border: "3px solid white",
        }}
      >
        <img
          src="https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405653/Pictures/iwitn9y38alzuqvzboi0.png"
          alt="/game Icon"
          style={{ width: "44px", height: "44px" }}
        />
      </Box>
    ),
  },
  {
    name: "Practice",
    route: "/learn",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "3px solid white",
          backgroundColor: "#ffefd5",
        }}
      >
        <img
          src="https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405680/Pictures/njgxjklshpsc9crdc4kc.png"
          alt="learn Icon"
          style={{ width: "44px", height: "44px" }}
        />
      </Box>
    ),
  },
  {
    name: "My Games",
    route: "/games",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "3px solid white",
          backgroundColor: "#ffefd5",
        }}
      >
        <img
          src="https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405709/Pictures/gmfzqn29lsrtljvhkac8.png"
          alt="search Icon"
          style={{ width: "40px", height: "40px" }}
        />
      </Box>
    ),
  },
  {
    name: "Favorites",
    route: "/favorites",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "3px solid white",
          backgroundColor: "#ffefd5",
        }}
      >
        <img
          src="https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405741/Pictures/meh6w6jjft8vh4h3osd5.png"
          alt="friends Icon"
          style={{ width: "40px", height: "40px" }}
        />
      </Box>
    ),
  },
];

function ResponsiveAppBar({ websocketStatus }) {
  const { userData } = useUser(); // 🔄 Pull globally from context
  const { currentUser } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [, setIsUserLoggedIn] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState({
    code: "en",
    flag: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405788/Pictures/ciqsflhvjmllwogcd2fg.svg",
  });
  const location = useLocation();
  const auth = getAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userDataFromServer } = useWebSocketManager();
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // To store user data
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [open, setOpen] = useState(false);

  const isConnected = websocketStatus === "open";
  const statusMessage = isConnected
    ? t("Connected to the server")
    : t("Disconnected from the server");

  const statusColor = isConnected ? "green" : "red";
  const iconSrc = isConnected
    ? "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743406028/Pictures/s6hdn3gsivvmxzpmty42.png"
    : "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743406033/Pictures/zcl1aiviwxnjb9wrge0q.png";

  useEffect(() => {
    const handleWebSocketMessage = (event) => {
      if (
        event.type === "message" &&
        event.message.messageType === "user_logged_out"
      ) {
        // 👇 Clear avatar image and force UI into "logged out" state
        setSelectedUser(null);
        setIsProfileDialogOpen(false);
        setIsUserLoggedIn(false); // 🔄 update this too
        //localStorage.removeItem("browserId", null);
      }
    };

    addListener(handleWebSocketMessage);
    return () => removeListener(handleWebSocketMessage);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Adjust breakpoint as needed
    };

    handleResize(); // Initial check

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Check auth state and set language on mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setIsUserLoggedIn(!!currentUser);
    });

    // Load persisted language from localStorage
    const persistedLanguage = localStorage.getItem("selectedLanguage");
    if (persistedLanguage) {
      const lang = JSON.parse(persistedLanguage);
      i18n.changeLanguage(lang.code);
      setSelectedLanguage(lang);
    }

    return unsubscribe;
  }, [auth]);

  useEffect(() => {
    if (userDataFromServer) {
      console.log(userDataFromServer);
    }
  }, [userDataFromServer]);

  useEffect(() => {
    if (userDataFromServer) {
      console.log(userDataFromServer);
    }
  }, [userDataFromServer]);

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleLogout = async () => {
    try {
      await signOut(auth); // Firebase auth sign out
      setIsUserLoggedIn(false);

      // Send logout message to WebSocket
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "user_logged_out";
      sendMessage(generalMessage);

      // Reset WebSocket connection
      resetWebSocket();
      localStorage.removeItem("uncompleted_game", null);
      localStorage.removeItem("browserId", null);
      // Redirect to login page and force page reload
      window.location.href = "/login";
      //window.location.reload(); // Ensures a fresh state after redirect
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleLanguageChange = (langCode, flag) => {
    const selectedLang = { code: langCode, flag };
    i18n.changeLanguage(langCode);
    setSelectedLanguage(selectedLang);
    setLanguageMenuAnchor(null);

    // Persist selected language to localStorage
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLang));
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleProfile = () => {
    setSelectedUser(userData); // Pass the current user data to the dialog
    setIsProfileDialogOpen(true); // Open the dialog
  };

  if (showLoginPage)
    return <OAuthSignInPage onBack={() => setShowLoginPage(false)} />;

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#607d8b", height: "65px" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={
              "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743427691/Pictures/liberty_1.png"
            } // Replace with your icon's path
            alt="Logo Icon"
            style={{
              display: isMobile ? "none" : "flex",
              marginRight: "1rem",
              height: "44px",
              width: "44px",
            }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {t("DEMOCRATIC CHESS")}
          </Typography>

          {/* Hamburger Menu Icon */}
          <Box
            sx={{
              marginLeft: "-50px",
              flexGrow: 0,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            ></IconButton>
            <Menu
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    navigate(page.route);
                    handleCloseNavMenu();
                  }}
                  sx={{
                    backgroundColor:
                      location.pathname === page.route
                        ? "rgba(255, 235, 59, 0.15)"
                        : "transparent",
                    borderLeft:
                      location.pathname === page.route
                        ? "5px solid #5f9ea0"
                        : "3px solid transparent",
                    transition: "all 0.1s ease-in-out",
                  }}
                >
                  {page.icon} {/* Add the icon here */}
                  <Typography
                    variant="caption"
                    sx={{
                      marginTop: "4px",
                      marginLeft: "4px",
                      fontSize: "0.7rem",
                      fontWeight: 500,
                      color: "black",
                      textShadow: "0 0 1px black",
                    }}
                  >
                    {t(page.name)}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Desktop Navigation Buttons */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
              gap: 4, // spacing between icons
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                sx={{
                  padding: 1,
                  border:
                    location.pathname === page.route
                      ? "2px solid #8cb2bf"
                      : "2px solid transparent",
                  borderRadius: "30%",
                  boxShadow:
                    location.pathname === page.route
                      ? "0 0 10px ffeb3b"
                      : "none",
                  transform:
                    location.pathname === page.route
                      ? "scale(1.1)"
                      : "scale(1.0)",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => navigate(page.route)}
              >
                {page.icon}
                <Typography
                  variant="caption"
                  sx={{
                    marginTop: "4px",
                    marginLeft: "5px",
                    fontSize: "0.6rem",
                    fontWeight: 300,
                    color: "white",
                    textShadow: "10 0 4px black",
                  }}
                >
                  {t(page.name)}
                </Typography>
              </Button>
            ))}
          </Box>

          {/* Selected Flag as Language Selector */}
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <Tooltip title={t("selectLanguage")}>
              <IconButton
                onClick={(e) => setLanguageMenuAnchor(e.currentTarget)}
                color="inherit"
              >
                <img
                  src={
                    selectedLanguage.flag ||
                    "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405788/Pictures/ciqsflhvjmllwogcd2fg.svg"
                  }
                  alt={selectedLanguage.code}
                  style={{ width: "24px", height: "24px" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={languageMenuAnchor}
            open={Boolean(languageMenuAnchor)}
            onClose={() => setLanguageMenuAnchor(null)}
          >
            {languageOptions.map((lang) => (
              <MenuItem
                key={lang.code}
                onClick={() => handleLanguageChange(lang.code, lang.flag)}
              >
                <img
                  src={lang.flag}
                  alt={lang.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {lang.name}
              </MenuItem>
            ))}
          </Menu>

          {/* WebSocket Status Icon */}
          <Box sx={{ mr: 2, cursor: "pointer" }} onClick={() => setOpen(true)}>
            <Tooltip title={statusMessage}>
              <img
                src={iconSrc}
                alt="WebSocket Status"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </Tooltip>
          </Box>

          {/* Status Dialog */}
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
              <InfoIcon sx={{ verticalAlign: "middle", mr: 1 }} />
              {t("Connection Status")}
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" sx={{ color: statusColor }}>
                {statusMessage}
              </Typography>
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                {t("Version")}: {AppVersion}
              </Typography>
            </DialogContent>
          </Dialog>

          {/* Use the NotEnoughCoinsDialog component */}
          <NotEnoughCoinsDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            //onBuyCoins={handleBuyCoins}
            buy={true}
            userData={userData}
          />

          {/* User Balance Display */}
          <Box
            onClick={handleOpenDialog}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px", // Reduce gap slightly for better compactness
              padding: "8px 12px", // Add internal spacing for a cohesive look
              backgroundColor: "#dcdcdc", // background for the balance box
              borderRadius: "15px", // Slightly larger rounding for modern design
              boxShadow: "8px 8px 8px black", // Subtle shadow for depth
              mr: 2, // Maintain right margin
              zIndex: 300,
              cursor: "pointer",
            }}
          >
            <Box
              component="img"
              src="https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"
              alt="Score Icon"
              sx={{
                // Slightly larger rounding for modern design
                width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
              }}
            />
            <Tooltip> </Tooltip>
            <Typography
              sx={{
                color: "black", // Ensure text is legible on gold background
                fontSize: "1.25rem", // Increase font size slightly for emphasis
                fontWeight: "bold", // Bold text for importance
                lineHeight: 1.2, // Adjust line height for better vertical alignment
              }}
            >
              {userData?.userStatistics?.currentScore ?? 0}{" "}
              {/* Fallback to 0 if no score */}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Box
              component="img"
              src="https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"
              alt="Coins Icon"
              sx={{
                width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
              }}
            />
            <Typography
              sx={{
                color: "black", // Ensure text is legible on gold background
                fontSize: "1.25rem", // Increase font size slightly for emphasis
                fontWeight: "bold", // Bold text for importance
                lineHeight: 1.2, // Adjust line height for better vertical alignment
              }}
            >
              {userData?.userStatistics?.coins ?? 0}{" "}
              {/* Fallback to 0 if no coins */}
            </Typography>
          </Box>

          {/* {isUserLoggedIn && (
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", mr: 2 }}>
              <AttachMoneyIcon sx={{ color: "gold", fontSize: "1.5rem" }} />
              <Typography sx={{ color: "white" }}>{userData?.userStatistics.currentScore}</Typography>
            </Box>
          )} */}

          <Box sx={{ flexGrow: 0 }}>
            {userData?.socialUser ||
            (window.FBInstant && window.FB_USER_DATA) ? (
              <>
                <Tooltip title={t("Open settings")}>
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{
                      p: 0,
                      marginTop: "1px",
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      //padding: 1,
                      border: "3px solid #ccc",
                      boxShadow: "0 0px 1px black",
                      borderRadius: "25px", // Rounded corners
                      //backgroundColor: buttonsBlockColor,
                      justifyContent: "center", // Centers content horizontally
                      alignItems: "center", // Centers content vertically
                      width: "100%", // Take full width of the parent container
                      height: "100%", // Optional: Take full height of the parent container
                    }}
                  >
                    <Avatar
                      alt={
                        currentUser?.displayName ||
                        (window.FBInstant && window.FB_USER_DATA?.name) ||
                        "Guest"
                      }
                      src={
                        userData?.url ||
                        currentUser?.photoURL ||
                        (window.FBInstant && window.FB_USER_DATA?.photo) ||
                        "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405455/Pictures/mt3ulobaoutca6qoeokv.png"
                      }
                      // onClick={handleProfile} // Open dialog on click
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {/* Profile Menu Item */}
                  <MenuItem
                    onClick={() => {
                      handleProfile(); // Open the avatar dialog
                      handleCloseUserMenu(); // Close the user menu
                    }}
                  >
                    <Typography textAlign="center">{t("Profile")}</Typography>
                  </MenuItem>

                  {/* Account Menu Item */}
                  <MenuItem
                    onClick={() => {
                      handleProfile(); // Open the avatar dialog
                      handleCloseUserMenu(); // Close the user menu
                    }}
                  >
                    <Typography textAlign="center">{t("Account")}</Typography>
                  </MenuItem>

                  {/* Dashboard Menu Item */}
                  <MenuItem
                    onClick={() => {
                      handleProfile(); // Open the avatar dialog
                      handleCloseUserMenu(); // Close the user menu
                    }}
                  >
                    <Typography textAlign="center">{t("Dashboard")}</Typography>
                  </MenuItem>

                  {/* Settings Menu Item */}
                  <MenuItem
                    onClick={() => {
                      handleProfile(); // Open the avatar dialog
                      handleCloseUserMenu(); // Close the user menu
                    }}
                  >
                    <Typography textAlign="center">{t("Settings")}</Typography>
                  </MenuItem>

                  {/* Logout Menu Item */}
                  {!(window.FBInstant && window.FB_USER_DATA) && (
                    <MenuItem
                      onClick={() => {
                        handleLogout();
                        handleCloseUserMenu(); // Close the user menu
                      }}
                    >
                      <Typography textAlign="center">{t("Logout")}</Typography>
                    </MenuItem>
                  )}
                </Menu>
              </>
            ) : (
              <Tooltip title={t("Login")}>
                <Button
                  onClick={handleLogin}
                  startIcon={<LoginIcon />}
                  sx={{ color: "white" }}
                ></Button>
              </Tooltip>
            )}
          </Box>
        </Toolbar>
      </Container>

      {/* Avatar Dialog */}
      <Profile
        open={isProfileDialogOpen}
        onClose={() => setIsProfileDialogOpen(false)} // Close dialog
        avatar={selectedUser} // Pass the selected user data
        userData={userData}
        onAddToFavorite={() => {
          //console.log(`${selectedUser?.name} added to favorites.`);
          setIsProfileDialogOpen(false); // Close the dialog
        }}
      />
    </AppBar>
  );
}

export default ResponsiveAppBar;
