import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import {
  Card,
  CardMedia,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  Divider,
  AccordionDetails,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import VideoPlayer from "./VideoPlayer";
import "../custom css/slider.css";
import CustomRadio from "./CustomRadio";
import ChessButton from "./ChessButton";
import { useNavigate } from "react-router-dom";

const GameOpponentSelectionItem = ({
  onOpponentSelected,
  onFriendSelected,
  game_type_id,
  name,
  description,
  imagePath,
  videoPath,
  autoPlay,
  gameType,
  isSelected,
  onSelect,
}) => {
  const TIME_LIMITS = { NO_LIMIT: -1, ONE_MINUTE: 1, THIRTY_MINUTES: 30};
  const USER = { HUMAN: "Human", AI: "AI", VIEWER: "Viewer" };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userType, setUserType] = useState(USER.HUMAN);
  const [timeLimit, setTimeLimit] = useState(TIME_LIMITS.NO_LIMIT);
  const [infoOpen, setInfoOpen] = useState(false);

  const isMobile = window.innerWidth <= 600;
  const mobileFontSize = "12px";
  const regularFontSize = "18px";
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const handleOpponentChange = (event) => {
    setUserType(event.target.value);
    localStorage.setItem("lastOpponentHumanOrAI", event.target.value); // Persist in local storage
  };

  const handleTimeLimitChange = (event) => {
    setTimeLimit(event.target.value);
    localStorage.setItem("lastTimeLimit", event.target.value); // Persist in local storage
  };

  React.useEffect(() => {
    const savedOpponentType = localStorage.getItem("lastOpponentHumanOrAI");

    const savedTimeLimit = localStorage.getItem("lastTimeLimit");

    if (savedOpponentType) {
      setUserType(savedOpponentType);
    }

    if (savedTimeLimit) {
      setTimeLimit(Number(savedTimeLimit));
    }
  }, []);

  React.useEffect(() => {
    if (gameType === "team_3") {
      setTimeLimit(TIME_LIMITS.ONE_MINUTE);
      setUserType(USER.HUMAN);
    }
    if (gameType === "skill_building") {
      setTimeLimit(TIME_LIMITS.NO_LIMIT);
      setUserType(USER.AI);
    }
  }, [
    gameType,
    TIME_LIMITS.NO_LIMIT,
    TIME_LIMITS.ONE_MINUTE,
    USER.AI,
    USER.HUMAN,
  ]);

  function handleSelectOpponentClick() {
    onOpponentSelected();
    if(gameType === "skill_building"){
      navigate("/learn");
    }
  }

  function handleSelectFriendClick() {
    onFriendSelected();
  }

  const cardColor = "black";

  const renderButton = (text, onClickHandler, image) => (
    <ChessButton
      onPress={onClickHandler}
      shadowColor="black"
      text={t(text)}
      icon={image && <img src={image} alt="icon" style={{ width: "30px", height: "30px", marginRight:"10px" }} />}
      fontSize={isMobile === true? mobileFontSize: regularFontSize}
      //size="large"
      height={isMobile === true? "50px": "60px"}
      width={isMobile === true? "170px": "220px"}
    ></ChessButton>
  );

  return (
    <Card
      onClick={(e) => {
        // Get the element that was clicked

        onSelect();
      }}
      sx={{
        minWidth: isMobile ? 350 : 700,
        maxWidth: isMobile ? 350 : 700,
        minHeight: isMobile ? 660: 850, 
        maxHeight: isMobile ? 660 : 850,
        borderRadius: "15px",
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
        border: "1px solid black",
        boxShadow: "0 5px 5px black",
        backgroundColor: cardColor,
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <CardMedia
        component="div" // CardMedia needs a valid container for custom content
        sx={{
          height: "auto", // Allow height to adjust dynamically
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        }}
      >
        <VideoPlayer
          isSelected={isSelected}
          videoSrc={videoPath}
          autoPlay={autoPlay}
          loop={false}
          muted
          style={{
            width: isMobile ? "100%" : "100%",
            height: isMobile ? "80%" : "100%",
            borderRadius: "1px",
          }}
        />
      </CardMedia>

      {/* Accordion for Content Section */}
      {game_type_id !== "0" && (
        <Accordion
          expanded={accordionExpanded}
          onChange={(e) => {
            e.stopPropagation(); // ✅ Prevents clicks from reaching the card
            setAccordionExpanded(!accordionExpanded);
          }}
          sx={{
            borderRadius: "15px",
            boxShadow: "none",
           
            minHeight: isMobile ? 580: 920, 
            maxHeight: isMobile ? 780 : 920,
          }}
        >
          <AccordionDetails>
            <Box
              onClick={(e) => e.stopPropagation()}
              sx={{
                zIndex: 100,
              }}
            >
              <Box>
                <div>
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    sx={{
                      color: "text.primary",
                      fontWeight: "bold",
                      border: "2px solid #ccc",
                      boxShadow: "0 10px 10px black",
                      borderRadius: "15px", // Rounded corners
                    }}
                  >
                    {name}
                    <Divider
                      sx={{ bgcolor: "secondary.light", marginTop: "15px" }}
                    />
                  </Typography>
                </div>

                <div>
                  <Typography
                    variant={isMobile ? "h6" : "h5"}
                    sx={{
                      color: "text.primary",
                      fontWeight: "bold",
                      marginTop: "10px",
                      //marginBottom: "15px",
                    }}
                  >
                    {t("Opponents")}
                  </Typography>
                </div>
                {/* Opponent Section */}

                <RadioGroup
                  row
                  value={userType}
                  onChange={handleOpponentChange}
                >
                  {gameType !== "skill_building" && (
                    <FormControlLabel
                      value={USER.HUMAN}
                      control={
                        <CustomRadio
                          uncheckedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415706/Pictures/myxhshqociqxmpsfhda8.png"}
                          checkedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415785/Pictures/rycx5ydwgh1mhfrwq0b7.png"}
                          width_c={isMobile ? "44px" : "74px"}
                          height_c={isMobile ? "44px" : "74px"}
                          width_u={isMobile ? "25px" : "34px"}
                          height_u={isMobile ? "25px" : "34px"}
                        />
                      } // Increase size of the radio icon
                      label={
                        <Typography
                          sx={{
                            fontSize: isMobile ? "0.8rem" : "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("Human")}
                        </Typography>
                      } // Increase font size of the label
                      disabled={gameType === "skill_building"}
                    />
                  )}
                  {gameType !== "team_3" && (
                    <FormControlLabel
                      value={USER.AI}
                      control={
                        <CustomRadio
                          uncheckedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415706/Pictures/myxhshqociqxmpsfhda8.png"}
                          checkedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415785/Pictures/rycx5ydwgh1mhfrwq0b7.png"}
                          width_c={isMobile ? "44px" : "74px"}
                          height_c={isMobile ? "44px" : "74px"}
                          width_u={isMobile ? "25px" : "34px"}
                          height_u={isMobile ? "25px" : "34px"}
                        />
                      } // Increase size of the radio icon
                      label={
                        <Typography
                          sx={{
                            fontSize: isMobile ? "0.8rem" : "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("AI")}
                        </Typography>
                      } // Increase font size of the label
                      disabled={gameType === "team_3"}
                    />
                  )}
                </RadioGroup>
              </Box>
              <Divider sx={{ bgcolor: "secondary.light", marginTop: "15px" }} />
              <Box sx={{ minHeight: '150px' }}>
                <Typography
                  variant={isMobile ? "h6" : "h5"}
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  {t("Game Duration")}
                </Typography>

                {/* Time Limitation Section */}

                <RadioGroup
                  row
                  value={timeLimit}
                  onChange={handleTimeLimitChange}
                >
                  {gameType !== "skill_building" && (
                    <FormControlLabel
                      value={TIME_LIMITS.ONE_MINUTE}
                      control={
                        <CustomRadio
                          uncheckedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415706/Pictures/myxhshqociqxmpsfhda8.png"}
                          checkedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415785/Pictures/rycx5ydwgh1mhfrwq0b7.png"}
                          width_c={isMobile ? "44px" : "74px"}
                          height_c={isMobile ? "44px" : "74px"}
                          width_u={isMobile ? "25px" : "34px"}
                          height_u={isMobile ? "25px" : "34px"}
                        />
                      } // Increase size of the radio icon
                      label={
                        <Typography
                          sx={{
                            fontSize: isMobile ? "0.8rem" : "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("1_minute_per_move")}
                        </Typography>
                      } // Increase font size of the label
                      disabled={gameType === "skill_building"}
                    />
                  )}
                  {gameType !== "team_3" && gameType !== "skill_building" && (
                    <FormControlLabel
                      value={TIME_LIMITS.THIRTY_MINUTES}
                      control={
                        <CustomRadio
                          uncheckedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415706/Pictures/myxhshqociqxmpsfhda8.png"}
                          checkedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415785/Pictures/rycx5ydwgh1mhfrwq0b7.png"}
                          width_c={isMobile ? "44px" : "74px"}
                          height_c={isMobile ? "44px" : "74px"}
                          width_u={isMobile ? "25px" : "34px"}
                          height_u={isMobile ? "25px" : "34px"}
                        />
                      } // Increase size of the radio icon
                      label={
                        <Typography
                          sx={{
                            fontSize: isMobile ? "0.8rem" : "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("30_minutes_per_game")}
                        </Typography>
                      } // Increase font size of the label
                      disabled={
                        gameType === "team_3" || gameType === "skill_building"
                      }
                    />
                  )}
                  {gameType !== "team_3" && (
                    <FormControlLabel
                      value={TIME_LIMITS.NO_LIMIT}
                      control={
                        <CustomRadio
                          uncheckedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415706/Pictures/myxhshqociqxmpsfhda8.png"}
                          checkedIconPath={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415785/Pictures/rycx5ydwgh1mhfrwq0b7.png"}
                          width_c={isMobile ? "44px" : "74px"}
                          height_c={isMobile ? "44px" : "74px"}
                          width_u={isMobile ? "25px" : "34px"}
                          height_u={isMobile ? "25px" : "34px"}
                        />
                      } // Increase size of the radio icon
                      label={
                        <Typography
                          sx={{
                            fontSize: isMobile ? "0.8rem" : "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {t("No_limit_Skill")}
                        </Typography>
                      } // Increase font size of the label
                      disabled={gameType === "team_3"}
                    />
                  )}
                </RadioGroup>

                <Divider
                  sx={{ bgcolor: "secondary.light", marginTop: "10px" }}
                />
              </Box>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 0, sm: 4 }}
                sx={{
                                   
                  flexDirection: "row",
                  gap: 1,
                  padding: 0.5,

                  justifyContent: "center", // Centers content horizontally
                  alignItems: "center", // Centers content vertically
                  width: "100%", // Take full width of the parent container
                  height: "100%", // Optional: Take full height of the parent container
                }}
              >
                {renderButton("Pick This Game", handleSelectOpponentClick, "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743932544/Pictures/pick_1.png")}

                {gameType !== "skill_building" &&
                  userType !== "AI" &&
                  renderButton("Challenge Friends", handleSelectFriendClick, "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743932484/Pictures/friend.png")}
              </Stack>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {game_type_id === "0" && (
        <>
          <Typography
            sx={{
              fontWeight: "bold",
              // marginTop: "15px",
              fontSize: isMobile ? "20px" : "30px",
              textAlign: "center",
              color: "white",
              border: "0px solid #ccc",
              boxShadow: "0 25px 25px black",
              padding: 3,
             
            }}
          >
            {t("Select Your Game Type")}
          </Typography>
         
        </>
      )}

      {game_type_id !== "0" && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setInfoOpen(true);
          }}
          sx={{
            position: "absolute",
            top: isMobile ? 225 : 422, // Adjust vertical position
            right: isMobile ? "35px" : "89px",
            color: "black", // Sets the color of the icon
            fontSize: isMobile ? 1 : 12, // Optional: Increase font size for the IconButton
           
            zIndex: 10,
          }}
        >
          <InfoIcon sx={{ fontSize: isMobile ? 18 : 25 }} />{" "}
          {/* Increase the size of the icon */}
        </IconButton>
      )}
      {/* Logo */}
      {game_type_id === "0" && (
        <Box
          sx={{
            position: "absolute",
            top: isMobile ? "250px" : "500px", // Adjust vertical position
            left: "50%", // Align the left edge to the center
            transform: "translateX(-50%)", // Shift the box horizontally by 50% of its width
            width: isMobile ? "270px" : "320px",
            height: isMobile ? "270px" : "320px",
            backgroundImage:  'url("https://res.cloudinary.com/dnlgtek2a/image/upload/v1743426566/Pictures/logo.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: 1,
            zIndex: 10,
            borderRadius: "10px",
           
          }}
        />
      )}

      {/* Popup for More Info */}
      <Dialog open={infoOpen} onClose={() => setInfoOpen(false)}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          <Typography>{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoOpen(false)}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default GameOpponentSelectionItem;
