import React, { useEffect, useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import LevelSelectionCarousel from "./LevelSelectionCarousel";
import OpponentSelectionCarousel from "./OpponentSelectionCarousel";
import useOpponentsList from "../entities/useOpponentsList.js";
import useLevelsList from "../entities/useLevelsList.js";
import MovingText from "react-moving-text";
import { sendMessage } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import { LottieAnimation } from "../lottie/LottieAnimation.js";
import VideoPlayer from "./VideoPlayer";
import GameInfoDialog from "./GameInfoDialog";
import { useWebSocket } from "../services/WebSocketProvider";
import NotEnoughCoinsDialog from "./NotEnoughCoinsDialog";
import ChessButton from "./ChessButton";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { GENERAL } from "../general/General";

const MainPage = ({ userData, updateBackground, setShowAppBar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { generalMessage } = state || {};
  const { t } = useTranslation();
  const opponents = useOpponentsList();
  const levels = useLevelsList();

  const [loading, setLoading] = useState(false);
  const [isMobile, ] = useState(window.innerWidth <= 600);
  const [openDialog, setOpenDialog] = useState(false);
  const [uncompletedGame, setUncompletedGame] = useState(null);
  const { uncompletedGameWS } = useWebSocket();
  const [gameInfoDialogOpen, setGameInfoDialogOpen] = useState(false);
  const [showOpponentSelection, setShowOpponentSelection] = useState(false);
  const [showLevelSelection, setShowLevelSelection] = useState(false);
  const [buttonSelectOpponent, setButtonSelectOpponent] = useState(
    t("Select_opponent")
  );
  const [buttonSelectLevel, setButtonSelectLevel] = useState(t("Level_Select"));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [neededCoins, setNeededCoins] = useState(null);
  const [actualCoins, setActualCoins] = useState(null);

  // Utility function for setting button labels from local storage
  const setButtonLabelsFromLocalStorage = useCallback(() => {
    const levelIndex = localStorage.getItem("lastSelectedIndexLevel");
    const opponentIndex = localStorage.getItem("lastSelectedOpponentIndex");
    const timeLimit = localStorage.getItem("lastTimeLimit");

    if (levelIndex && levels && levels.length > 0) {
      setButtonSelectLevel(levels[levelIndex].name);
    }

    if (opponentIndex && opponents && opponents.length > 0) {
      setButtonSelectOpponent(opponents[opponentIndex]?.name);
    }

    // Set time limit text
    if (timeLimit === "1") {
      setTimeLimitText(t("1_minute_per_move"));
    } else if (timeLimit === "30") {
      setTimeLimitText(t("30_minutes_per_game"));
    } else {
      setTimeLimitText(t("No_limit"));
    }
  }, [levels, opponents, t]);

  // Utility function for setting time limit text
  const setTimeLimitText = (text) => {
    // You can add state here if you need to use this text elsewhere
    // Or just use it directly in the logic that needs it.
  };

  useEffect(() => {
    setShowAppBar(true);
  }, [setShowAppBar]);

  useEffect(() => {
    setButtonLabelsFromLocalStorage();
    const uncompletedGameFromStorage = localStorage.getItem("uncompleted_game");
    if (uncompletedGameFromStorage) {
      setUncompletedGame(JSON.parse(uncompletedGameFromStorage));
    }
  }, [setButtonLabelsFromLocalStorage]);

  useEffect(() => {
    if (uncompletedGameWS) {
      setUncompletedGame(uncompletedGameWS);
    }
  }, [uncompletedGameWS]);

  useEffect(() => {
    setLoading(false);
    const handlePopState = () => {};
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [navigate]);

  useEffect(() => {
    document.body.style.overflow = loading
      ? "hidden"
      : isMobile
        ? "auto"
        : "hidden";
    updateBackground({ image: GENERAL.BACKGROUND});
    return () => (document.body.style.overflow = "auto");
  }, [isMobile, loading, updateBackground]);

  useEffect(() => {
    if (generalMessage) {
      playGame();
    }
  }, [generalMessage]);

  const cancelGame = () => {
    setLoading(false);
    const generalMessage = {
      ...generalMessageTemplate,
      messageType: "new_game_request_cancel",
    };
    sendMessage(generalMessage);
  };

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);
  const handleBuyCoins = () => setIsDialogOpen(false);
  const handleWatchAd = () => setIsDialogOpen(false);

  const findLevelByTypeId = (levelTypeId) => {
    const storedLevels = JSON.parse(localStorage.getItem("levels"));
    return (
      storedLevels?.find((level) => level.levelTypeId === +levelTypeId) || null
    );
  };

  const playGame = () => {
    const levelId = localStorage.getItem("lastSelectedLevelId");
    const opponentIndex = localStorage.getItem("lastSelectedOpponentIndex");
    const storedOpponents = JSON.parse(localStorage.getItem("opponents"));

    const levelsObj = findLevelByTypeId(levelId);
    const opponentObj = storedOpponents?.[+opponentIndex];

    if (opponentObj?.gameType === "skill_building") {
      navigate("/learn", { state: { userData } });
      return;
    }

    if (!levelId || !opponentIndex) {
      setOpenDialog(true);
      return;
    }

    if (
      userData &&
      (Math.abs(levelsObj?.lose) > userData.userStatistics.coins ||
        Math.abs(levelsObj?.draw) > userData.userStatistics.coins)
    ) {
      setNeededCoins(
        Math.abs(levelsObj?.lose) > Math.abs(levelsObj.draw)
          ? Math.abs(levelsObj?.lose)
          : Math.abs(levelsObj?.draw)
      );
      setActualCoins(userData.userStatistics.coins);
      
      setTimeout(() => {
        handleOpenDialog(); 
      }, 100); // delay slightly to let the button animation finish
      return;
    }

    setLoading(true);

    setShowAppBar(false);

    const generalMessageToSend = { ...generalMessageTemplate };
    generalMessageToSend.messageType = "new_game_request";
    generalMessageToSend.gameInfo.gameOpponentId = opponentIndex;
    generalMessageToSend.gameInfo.gameLevelId = levelId;

    generalMessageToSend.gameInfo.pointsWin = levelsObj?.win;
    generalMessageToSend.gameInfo.pointsLose = levelsObj?.lose;
    generalMessageToSend.gameInfo.pointsDraw = levelsObj?.draw;

    generalMessageToSend.gameInfo.coinsWin = levelsObj?.winCoins;
    generalMessageToSend.gameInfo.coinsDraw = levelsObj?.drawCoins;
    generalMessageToSend.gameInfo.coinsLose = levelsObj?.loseCoins;

    generalMessageToSend.gameInfo.gameTeamType = opponentObj?.gameType;
    generalMessageToSend.gameInfo.userType = "Human";
    generalMessageToSend.gameInfo.opponentType = localStorage.getItem(
      "lastOpponentHumanOrAI"
    );
    generalMessageToSend.gameInfo.secondsBasedOnGameTimeType =
      localStorage.getItem("lastTimeLimit");
    generalMessageToSend.gameInfo.aiLevel = localStorage.getItem("lastAILevel");

    sendMessage(generalMessageToSend);
  };

  const playWithFriends = () => {
    const levelIndex = localStorage.getItem("lastSelectedIndexLevel");
    const opponentIndex = localStorage.getItem("lastSelectedOpponentIndex");
    const levelsObj = levels[levelIndex];
    const opponentObj = opponents[opponentIndex];

    if (!levelsObj || !opponentObj) {
      setOpenDialog(true);
      return;
    }

    navigate("/favorites", { state: { userData, joinGame: true } });
  };

  const handleSelectOpponentClick = () => setShowOpponentSelection(true);
  const handleSelectLevelClick = () => setShowLevelSelection(true);
  const handleUncompletedGameClick = () => setGameInfoDialogOpen(true);

  const onOpponentSelected = () => {
    setShowOpponentSelection(false);
    setButtonLabelsFromLocalStorage();
  };

  const onLevelSelected = () => {
    setShowLevelSelection(false);
    setButtonLabelsFromLocalStorage();
  };

  const onContinue = () => {
    setGameInfoDialogOpen(false);
    const uncompletedGameData = JSON.parse(
      localStorage.getItem("uncompleted_game")
    );
    if (uncompletedGameData) {
      const generalMessageToSend = { ...generalMessageTemplate };
      generalMessageToSend.messageType = "continue_game";
      generalMessageToSend.gameInfo = uncompletedGameData;

      sendMessage(generalMessageToSend);
    }
  };

  const getFontSize = () => (isMobile ? "13px" : "18px");
  const getButtonWidth = () => (isMobile ? "160px" : "230px");
  const getButtonHeight = () => (isMobile ? "50px" : "60px");

  const renderButton = (text, onClickHandler, image) => (
    <ChessButton
      onPress={onClickHandler}
      primaryColor="#e0e0e0"
      shadowColor="#424242"
      hoverColor="#fafafa"
      text={t(text)}
      icon={image && <img src={image} alt="icon" style={{ width: "30px", height: "30px", marginRight:"10px" }} />}
      fontColor="#000000"
      fontSize={getFontSize()}
      height={getButtonHeight()}
      width={getButtonWidth()}
    />
  );

  return (
    <div
      style={{
        flexDirection: "column",
        alignItems: "center",
        padding: isMobile ? 0 : 0,
        overflow: "hidden",
      }}
    >
      {/* Use the NotEnoughCoinsDialog component */}
      <NotEnoughCoinsDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onBuyCoins={handleBuyCoins}
        onWatchAd={handleWatchAd}
        neededCoins={neededCoins}
        actualCoins={actualCoins}
      />
      {/* Video Background */}
      <VideoPlayer
        isSelected={true}
        videoSrc={"https://res.cloudinary.com/dnlgtek2a/video/upload/v1743368069/video/mhmthyliy6zoj7qxojac.mp4"}
        autoPlay={false}
        loop={false}
        muted
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 1,
        }}
      />

      {/*Game Info Dialog */}
      <GameInfoDialog
        open={gameInfoDialogOpen}
        onClose={() => setGameInfoDialogOpen(false)}
        gameInfo={uncompletedGame}
        operations={true}
        onContinue={onContinue}
      />

      {/* Opponent and Level Selection Carousels */}
      {loading === false && (
        <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 200,
          width: isMobile ? "95%" : "auto", // responsive width
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
          {showOpponentSelection && (
            <OpponentSelectionCarousel
              onOpponentSelected={onOpponentSelected}
              onFriendSelected={playWithFriends}
              scaleConfig={{ selected: isMobile ? 1 : 1 }}
              setShowAppBar={setShowAppBar}
            />
          )}

          {showLevelSelection && (
            <LevelSelectionCarousel
              onLevelSelected={onLevelSelected}
              scaleConfig={{ selected: isMobile ? 1 : 1 }}
              setShowAppBar={setShowAppBar}
            />
          )}
        </Box>
      )}

      {!showOpponentSelection && !showLevelSelection && !loading && (
        <div>
          <Stack
            direction="column"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              zIndex: 200,
            }}
          >
            {/* Top Buttons */}
            <Stack
              direction="row"
              spacing={3}
              sx={{
                position: "absolute", // absolute positioning relative to parent
                bottom: 130,
                zIndex: 999, // make sure it's above the video
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant={isMobile ? "body2" : "subtitle1"}
                  sx={{ color: "gold", marginBottom: "4px", backgroundColor: "black", padding: "5px", borderRadius: "15px" }}
                >
                  {t("Select Your Game Type")}
                </Typography>
                {renderButton(buttonSelectOpponent, handleSelectOpponentClick, "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743927393/Pictures/swords_1.png")}
              </Box>

              <Box textAlign="center">
                <Typography
                  variant={isMobile ? "body2" : "subtitle1"}
                  sx={{ color: "gold", marginBottom: "4px", backgroundColor: "black", padding: "5px", borderRadius: "15px" }}
                >
                  {t("Select Outcome Rewards")}
                </Typography>
                {renderButton(buttonSelectLevel, handleSelectLevelClick, "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743927241/Pictures/treasure_1.png")}
              </Box>
            </Stack>
          </Stack>
        </div>
      )}

      {/* Play Button */}
      {loading === false && (
        <div
          style={{
            position: "absolute",
            marginRight: "auto",
            bottom: 35,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Play Button */}
          {showLevelSelection === false && showOpponentSelection === false && (
            <ChessButton
              onPress={playGame}
              icon={<PlayArrowIcon sx={{ fontSize: 20 }} />}
              text={t("Play!")}
              fontSize={getFontSize()}
              height={getButtonHeight()}
              width={getButtonWidth()}
            />
          )}
        </div>
      )}

      {/* Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            backgroundImage: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415495/Pictures/cyume5jns2oovggj8z4g.png",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "15px",
            boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.3)",
            opacity: 0.9,
            zIndex: -1,
          },
        }}
      >
        <DialogTitle
          sx={{
            marginTop: "50px",
            fontSize: "1.6rem",
            fontWeight: "bold",
            color: "black",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          {t("Selection Required")}
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "black",
            fontSize: "1.5rem",
            gap: "20px",
            padding: "10px",
          }}
        >
          <MovingText
            type="typewriter"
            dataText={[t("Select_opponent"), t("Level_Select")]}
          />

          <LottieAnimation
            path={"https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743418439/Lottie/needToSelect.json"}
            width="100%"
            autoplay
            loop
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", width: "100%" }}>
          <Button
            onClick={() => setOpenDialog(false)}
            variant="contained"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              backgroundColor: "#ff5722",
              color: "white",
              padding: "10px 20px",
              borderRadius: "10px",
              boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "#e64a19",
              },
            }}
          >
            {t("OK")}
          </Button>
        </DialogActions>
      </Dialog>

      <Stack
        direction="column"
        spacing={2}
        sx={{
          marginTop: isMobile ? "10px" : "20px",
          zIndex: 200,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {/* Loading Text Box */}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "black",
              color: "yellow",
              borderRadius: "10px",
              textAlign: "center",
              width: isMobile ? "90%" : "60%",
              padding: isMobile ? "10px" : "15px",
            }}
          >
            <Typography
              variant={isMobile ? "h6" : "h4"}
              sx={{ textAlign: "center", zIndex: 1000 }}
            >
              {t("Wait")}
            </Typography>
          </Box>
        )}

        {/* Loading Animation */}
        {loading && (
          <LottieAnimation
            path={"https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743418492/Lottie/search.json"}
            width={isMobile ? "250px" : "400px"}
            height={isMobile ? "250px" : "400px"}
            autoplay
            loop
          />
        )}

        {/* Stop Waiting Text Box */}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              backgroundColor: "transparent",
              textAlign: "center",
              width: isMobile ? "90%" : "60%",
              marginTop: isMobile ? "20px" : "50px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "white", zIndex: 1000 }}
            >
              {t("Stop_Waiting")}
            </Typography>
          </Box>
        )}

        {/* Cancel Animation */}
        {loading && (
          <LottieAnimation
            path={"https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743418553/Lottie/cancel.json"}
            width={isMobile ? "50px" : "60px"}
            height={isMobile ? "50px" : "60px"}
            marginTop={isMobile ? "10px" : "20px"}
            autoplay
            loop={false}
            onClick={cancelGame}
          />
        )}
      </Stack>
    </div>
  );
};

export default MainPage;
