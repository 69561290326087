import React, { useState, useEffect, useMemo } from 'react';

import {
  sendMessage,
  addListener,
  removeListener,
} from "../services/websocketManager";
import { LottieAnimation } from "../lottie/LottieAnimation.js";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { Chessboard } from "react-chessboard";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { useUser } from "../authMethods/UserContext";
import { GENERAL } from "../general/General";

const UserGames = ({ updateBackground }) => {
 
  const { t } = useTranslation();
  const [games, setGames] = useState([]);
  const navigate = useNavigate();
  const { userData } = useUser(); // 🔄 Pull globally from context
  // State
 
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc'); // 'asc' or 'desc'
  const [loading, setLoading] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  // State for Dialog (More Info)
  const [selectedGame, setSelectedGame] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [fenPosition, setFenPosition] = useState(""); // FEN string for chess board
  const desktopScreen = "500";
  const mobileScreen = "270";
  useEffect(() => {
    updateBackground({
      image: GENERAL.BACKGROUND
    });
    
    // Define a function to handle WebSocket events
    const handleWebSocketEvent = (event) => {
      if (
        event.type === "message" &&
        event.message.messageType === "game_history"
      ) {
        const gameData = event.message.gameInfos;
        
        setGames(gameData.content || []);
        setTotalItems(gameData.totalElements || gameData.content.length);
        setLoading(false); // Stop loading spinner
      }
    };
    
    // Subscribe to WebSocket events
    addListener(handleWebSocketEvent);
    
    // Initial data load
    requestGamesForPage(currentPage, itemsPerPage);

    // Cleanup on component unmount
    return () => {
      removeListener(handleWebSocketEvent);
    };
  }, [currentPage, itemsPerPage, updateBackground]);

  // Request games when pagination parameters change
  useEffect(() => {
    setLoading(true);
    requestGamesForPage(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    //console.log("Current page updated:", currentPage);
  }, [currentPage]);

  useEffect(() => {
     if(userData){
      if(!userData.email && !userData.socialUser){
        Swal.fire({
          title: t("Please log in to view your game history"),
          icon: "warning",
          draggable: true,
          confirmButtonText: t("OK"),
        });
        navigate("/login");
      }
     }
     else{
      Swal.fire({
        title: t("Please log in to view your game history"),
        icon: "warning",
        draggable: true,
        confirmButtonText: t("OK"),
      });
      navigate("/login");
     }
    }, [navigate, t, userData]);

  // Function to request games for a specific page - removed sorting parameters
  function requestGamesForPage(page, perPage) {
    const generalMessage = {
      messageType: "game_history",
      page: page - 1, // Adjust for 0-indexed backend
      itemsPerPage: perPage
      // Removed sortBy and sortDirection parameters
    };
    sendMessage(generalMessage);
  }

  const styles = {
    container: {
      width: '100%',
      padding: '20px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
      fontFamily: 'Arial, sans-serif'
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '1rem'
    },
    searchContainer: {
      position: 'relative',
      marginBottom: '1rem',
      display: 'flex',
      alignItems: 'center',
      gap: '10px'
    },
    searchIcon: {
      position: 'absolute',
      left: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#9ca3af'
    },
    searchInput: {
      width: '100%',
      padding: '8px 8px 8px 36px',
      fontSize: '0.875rem',
      border: '1px solid #d1d5db',
      borderRadius: '4px'
    },
    searchButton: {
      padding: '8px 16px',
      backgroundColor: '#4f46e5',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '0.875rem',
      transition: 'background-color 0.2s'
    },
    searchButtonHover: {
      backgroundColor: '#4338ca'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginBottom: '1rem'
    },
    tableHead: {
      backgroundColor: '#f9fafb'
    },
    tableHeadCell: {
      padding: '12px 16px',
      textAlign: 'left',
      fontSize: '0.75rem',
      fontWeight: '600',
      textTransform: 'uppercase',
      color: '#6b7280',
      cursor: 'pointer'
    },
    tableCell: {
      padding: '16px',
      fontSize: '0.875rem',
      borderBottom: '1px solid #e5e7eb'
    },
    tableRow: {
      transition: 'background-color 0.2s'
    },
    tableRowHover: {
      backgroundColor: '#f9fafb'
    },
    actionButton: {
      backgroundColor: '#e0e7ff',
      color: '#4f46e5',
      border: 'none',
      padding: '6px 12px',
      borderRadius: '9999px',
      fontSize: '0.875rem',
      cursor: 'pointer',
      transition: 'all 0.2s'
    },
    actionButtonHover: {
      backgroundColor: '#c7d2fe',
      color: '#4338ca'
    },
    mobileCard: {
      padding: '16px',
      backgroundColor: '#f9fafb',
      borderRadius: '8px',
      marginBottom: '16px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
    },
    mobileCardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '8px'
    },
    mobileCardContent: {
      marginBottom: '4px'
    },
    pagination: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px 0',
      borderTop: '1px solid #e5e7eb'
    },
    paginationText: {
      fontSize: '0.875rem',
      color: '#4b5563'
    },
    paginationButton: {
      padding: '8px 12px',
      border: '1px solid #d1d5db',
      backgroundColor: 'white',
      borderRadius: '4px',
      marginRight: '4px',
      cursor: 'pointer'
    },
    paginationButtonActive: {
      backgroundColor: '#4f46e5',
      color: 'white',
      borderColor: '#4f46e5'
    },
    paginationButtonDisabled: {
      backgroundColor: '#f3f4f6',
      color: '#9ca3af',
      cursor: 'not-allowed'
    },
    paginationControls: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    itemsPerPageContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '16px',
      justifyContent: 'space-between'
    },
    itemsPerPageLabel: {
      fontSize: '0.875rem',
      color: '#4b5563',
      marginRight: '8px'
    },
    itemsPerPageSelect: {
      padding: '4px 8px',
      borderRadius: '4px',
      border: '1px solid #d1d5db',
      fontSize: '0.875rem'
    },
    hidden: {
      display: 'none'
    },
    visible: {
      display: 'block'
    },
    pageInfo: {
      fontSize: '0.875rem',
      color: '#4b5563',
      marginLeft: '8px',
      display: 'inline-block'
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0'
    },
    paginationModeSwitch: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    switchLabel: {
      fontSize: '0.875rem',
      color: '#4b5563'
    },
    switchInput: {
      position: 'relative',
      display: 'inline-block',
      width: '40px',
      height: '20px'
    },
    switchSlider: {
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#ccc',
      transition: '0.4s',
      borderRadius: '34px'
    },
    switchSliderBefore: {
      position: 'absolute',
      content: '""',
      height: '16px',
      width: '16px',
      left: '2px',
      bottom: '2px',
      backgroundColor: 'white',
      transition: '0.4s',
      borderRadius: '50%'
    },
    switchSliderChecked: {
      backgroundColor: '#4f46e5'
    },
    switchSliderBeforeChecked: {
      transform: 'translateX(20px)'
    }
  };

  // Enhanced client-side filtering, sorting and pagination
  const filteredGameData = useMemo(() => {
    // Start with the complete list of games
    let filteredData = [...games];
      
    // Apply search filter
    if (searchQuery) {
      filteredData = filteredData.filter(game => 
        (game?.color === "white" ? game?.userName : (game?.opponents?.[0] ?? t("Unknown Opponent"))).toLowerCase().includes(searchQuery.toLowerCase()) ||
        (game?.color !== "white" ? game?.userName : (game?.opponents?.[0] ?? t("Unknown Opponent"))).toLowerCase().includes(searchQuery.toLowerCase()) ||
        (t(game?.gameStatus)).toLowerCase().includes(searchQuery.toLowerCase()) ||
        (t(game?.gameGeneralStatus + "_1")).toLowerCase().includes(searchQuery.toLowerCase()) ||
        (game?.startGameTime).includes(searchQuery.toLowerCase()) || 
        (t(game?.opponentType)).toLowerCase().includes(searchQuery.toLowerCase()) ||
        (t(game?.gameTeamType)).toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
     
    // Apply client-side sorting based on sortBy and sortDirection
    if (sortBy) {
      filteredData.sort((a, b) => {
        let valueA, valueB;

        // Extract the values to sort by based on the sortBy key
        switch (sortBy) {
          case 'gameId':
            valueA = a.gameId || '';
            valueB = b.gameId || '';
            break;
          case 'startDate':
              valueA = a.startGameTime || '';
              valueB = b.startGameTime || '';
              break;
          case 'whitePlayer':
            valueA = a.color === "white" ? a.userName : (a.opponents?.[0] ?? "Unknown");
            valueB = b.color === "white" ? b.userName : (b.opponents?.[0] ?? "Unknown");
            break;
          case 'blackPlayer':
            valueA = a.color !== "white" ? a.userName : (a.opponents?.[0] ?? "Unknown");
            valueB = b.color !== "white" ? b.userName : (b.opponents?.[0] ?? "Unknown");
            break;
          case 'status':
            valueA = a.gameGeneralStatus || '';
            valueB = b.gameGeneralStatus || '';
            break;
          case 'info':
            valueA = a.gameStatus || '';
            valueB = b.gameStatus || '';
            break;
          
            case 'opponent':
            valueA = a.opponentType || 0;
            valueB = b.opponentType || 0;
            break;

            case 'gameType':
              valueA = a.gameTeamType || 0;
              valueB = b.gameTeamType || 0;
              break;

          default:
            valueA = a[sortBy] || '';
            valueB = b[sortBy] || '';
        }

        // Handle string comparisons
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          const comparison = valueA.localeCompare(valueB);
          return sortDirection === 'asc' ? comparison : -comparison;
        }
        
        // Handle numeric comparisons
        if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }
     
    // Update total count for pagination
  //const totalFilteredItems = filteredData.length;
  // Move this outside of useMemo to avoid state updates inside
  // and instead do it in a useEffect that depends on filteredData
  
  // Return only the slice for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  // Handle case where current page is beyond available pages
  if (startIndex >= filteredData.length && currentPage > 1 && filteredData.length > 0) {
    // This helps ensure we don't end up with an empty page
    //let currentPage = Math.max(1, Math.ceil(filteredData.length / itemsPerPage));
    //setCurrentPage(currentPage);
    return filteredData.slice(0, itemsPerPage);
  }
  
  return filteredData.slice(startIndex, startIndex + itemsPerPage);
}, [games, searchQuery, sortBy, sortDirection, currentPage, itemsPerPage, t]);

  // State variables
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [hoverRowId, setHoverRowId] = useState(null);
  const [hoverButtonId, setHoverButtonId] = useState(null);
  const [searchButtonHover, setSearchButtonHover] = useState(false);

  // Handle window resize for responsive design
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle search form submission - now just applies client filtering
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to first page on new search
  };

  // Improved requestSort function for client-side sorting
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'desc';
    }
    
    // Update sort configuration for UI feedback
    setSortConfig({ 
      key, 
      direction: direction === 'asc' ? 'ascending' : 'descending' 
    });
    
    // Update sort state for data processing
    setSortBy(key);
    setSortDirection(direction);
    
    // Reset to first page when sorting changes
    setCurrentPage(1);
  };

  // Handle info button click
  const handleInfoClick = (game) => {
    
    openDialog(game);
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Get sort direction indicator
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    return '';
  };

  // Generate page numbers to display (with ellipsis for large page counts)
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5; // Number of page buttons to show
    
    if (totalPages <= maxVisiblePages) {
      // If not many pages, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Complex pagination with ellipsis
      if (currentPage <= 3) {
        // Current page is near the start
        for (let i = 1; i <= 4; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Current page is near the end
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Current page is in the middle
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }
    
    return pageNumbers;
  };

  // Handle jump to specific page
  const jumpToPage = (page) => {
    const pageNumber = Number(page);
  if (pageNumber >= 1 && pageNumber <= totalPages) {
    //console.log("Before setCurrentPage, currentPage is:", currentPage); // Log before
    setCurrentPage(prevPage => {
      //console.log("Updating to new page:", pageNumber, "Previous page:", prevPage);
      return pageNumber !== prevPage ? pageNumber : prevPage; // Prevent React from ignoring updates
    });
    requestGamesForPage(pageNumber, itemsPerPage);
    window.scrollTo(0, 0);
  }
  };

  // Handle direct page input
  const handlePageInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 1 && value <= totalPages) {
      setCurrentPage(value);
    }
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = Number(e.target.value);
    setItemsPerPage(newItemsPerPage);
    
    // Adjust current page to ensure current view items remain visible when possible
    const firstItemIndex = (currentPage - 1) * itemsPerPage;
    const newCurrentPage = Math.floor(firstItemIndex / newItemsPerPage) + 1;
    setCurrentPage(Math.min(newCurrentPage, Math.ceil(totalItems / newItemsPerPage)));
  };

  const openDialog = (game) => {
    setSelectedGame(game);
    setFenPosition(game.fen || ""); // Set FEN position for chess board
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedGame(null);
  };
  
  // Calculate current range for display
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

  return (
    
    <div style={styles.container}>
      {/* Dialog for Game Info */}
      <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth="md">
        {selectedGame && (
          <>
            <DialogTitle>{t("Game Details")}</DialogTitle>
            <DialogContent>
              <div className="game-info">
                <p><strong>{t("Game ID")}:</strong> {selectedGame.gameId}</p>
                <p><strong>{t("Date")}:</strong> {selectedGame.startGameTime}</p>
                <p><strong>{t("White Player")}:</strong> {selectedGame.userName}</p>
                <p><strong>{t("Black Player")}:</strong> {selectedGame.opponents?.[0] ?? t("Unknown")}</p>
                <p><strong>{t("Status")}:</strong> {t(selectedGame.gameStatus)}</p>
                <p><strong>{t("Opponent Type")}:</strong> {selectedGame.opponentType}</p>
                <p><strong>{t("Game Type")}:</strong> {t(selectedGame.gameTeamType)}</p>
                {/* <p><strong>{t("Time Limit")}:</strong> {selectedGame.timeLimit ?? "N/A"}</p> */}
              </div>

              {/* Chess Board (FEN Position) */}
              <div className="chessboard-container">
                <h4>{t("Game Board")}</h4>
                {/* <Chessboard position={fenPosition} width={350} /> */}
                 <Chessboard
                                position={selectedGame.gameHistory?.[selectedGame.gameHistory.length - 2]?.opponentMove?.commandFen ||
                                  selectedGame.gameHistory?.[selectedGame.gameHistory.length - 2]?.move?.commandFen || "start"}
                                //onPieceDrop={handleMove}
                                boardOrientation={selectedGame.color}
                                boardWidth={
                                  window.innerWidth > 600 ? desktopScreen : mobileScreen
                                }
                              />
              </div>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" color="primary" onClick={() => alert("Game Log Coming Soon!")}>
                {t("Show Game Log")}
              </Button>
              <Button variant="outlined" color="secondary" onClick={closeDialog}>
                {t("Close")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <h2 style={styles.title}>{t("Chess Games")}</h2>

      
      
      {/* Search Form */}
      <form onSubmit={handleSearch}>
        <div style={styles.searchContainer}>
          <div style={{ position: 'relative', flexGrow: 1 }}>
            <div style={styles.searchIcon}>
              {/* Search icon would go here */}
            </div>
            <input
              type="text"
              style={styles.searchInput}
              placeholder={t("Search games...")}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <button 
            type="submit"
            style={{
              ...styles.searchButton,
              ...(searchButtonHover ? styles.searchButtonHover : {})
            }}
            onMouseEnter={() => setSearchButtonHover(true)}
            onMouseLeave={() => setSearchButtonHover(false)}
          >
            {t("Search")}
          </button>
        </div>
      </form>
      
      {/* Loading indicator */}
      {loading ? (
        <div style={styles.loaderContainer}>
           <LottieAnimation
                    path={"https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743417798/Lottie/heart.json"}
                    width="70px"
                    autoplay
                    loop
                  />
        </div>
      ) : filteredGameData.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          {t("No games found.")}
        </div>
      ) : (
        <>
          {/* Table for desktop */}
          <div style={isMobile ? styles.hidden : styles.visible}>
            <table style={styles.table}>
              <thead style={styles.tableHead}>
                <tr>
                <th 
                    style={styles.tableHeadCell}
                    onClick={() => requestSort('gameId')}
                  >
                    {t("Game ID/Name")} {getSortIndicator('gameId')}
                  </th>
                  <th 
                    style={styles.tableHeadCell}
                    onClick={() => requestSort('startDate')}
                  >
                   {t("Start Date")} {getSortIndicator('startDate')}
                  </th>
                  <th 
                    style={styles.tableHeadCell}
                    onClick={() => requestSort('whitePlayer')}
                  >
                   {t("White Player")} {getSortIndicator('whitePlayer')}
                  </th>
                  <th 
                    style={styles.tableHeadCell}
                    onClick={() => requestSort('blackPlayer')}
                  >
                   {t("Black Player")} {getSortIndicator('blackPlayer')}
                  </th>
                  <th 
                    style={styles.tableHeadCell}
                    onClick={() => requestSort('status')}
                  >
                    {t("Status")} {getSortIndicator('status')}
                  </th>
                  <th 
                    style={styles.tableHeadCell}
                    onClick={() => requestSort('info')}
                  >
                    {t("Info_1")} {getSortIndicator('info')}
                  </th>
                  <th 
                    style={styles.tableHeadCell}
                    onClick={() => requestSort('opponent')}
                  >
                    {t("Opponent Type")} {getSortIndicator('opponent')}
                  </th>
                  <th 
                    style={styles.tableHeadCell}
                    onClick={() => requestSort('gameType')}
                  >
                    {t("Game Type")} {getSortIndicator('gameType')}
                  </th>
                  <th style={styles.tableHeadCell}>
                    {t("Actions")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredGameData.map((game) => (
                  <tr 
                    key={game.id} 
                    style={{
                      ...styles.tableRow,
                      ...(hoverRowId === game.id ? styles.tableRowHover : {})
                    }}
                    onMouseEnter={() => setHoverRowId(game.id)}
                    onMouseLeave={() => setHoverRowId(null)}
                  >
                     <td style={styles.tableCell}>
                      {t(game.gameId)}
                    </td>
                    <td style={styles.tableCell}>
                      {t(game.startGameTime)}
                    </td>
                    <td style={styles.tableCell}>
                    {game.color === "white" ? game.name : (game.opponentUsers?.[0].name ?? t("Unknown Opponent"))}
                    </td>
                    <td style={styles.tableCell}>
                    {game.color !== "white" ? game.name : (game.opponentUsers?.[0].name ?? t("Unknown Opponent"))}
                    </td>
                    <td style={styles.tableCell}>
                    {t(game.gameGeneralStatus + "_1")}
                    </td>

                    <td style={styles.tableCell}>
                      {t(game.gameStatus)}
                    </td>
                    <td style={styles.tableCell}>
                      {t(game.opponentType)}
                    </td>
                    <td style={styles.tableCell}>
                      {t(game.gameTeamType)}
                    </td>
                    <td style={styles.tableCell}>
                      <button 
                        onClick={() => handleInfoClick(game)}
                        style={{
                          ...styles.actionButton,
                          ...(hoverButtonId === game.id ? styles.actionButtonHover : {})
                        }}
                        onMouseEnter={() => setHoverButtonId(game.id)}
                        onMouseLeave={() => setHoverButtonId(null)}
                      >
                        {t("More Info")}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Cards for mobile */}
          <div style={isMobile ? styles.visible : styles.hidden}>
            {filteredGameData.map((game) => (
              <div key={game.id} style={styles.mobileCard}>
                <div style={styles.mobileCardHeader}>
                  <div>
                    <div style={{fontWeight: 'regular'}}>{t(game.startGameTime)}</div>
                    <div style={{fontWeight: 'regular'}}>{game.color === "white" ? game.name : (game.opponentUsers?.[0].name ?? t("Unknown Opponent"))}</div>
                    <div style={{color: '#6b7280'}}>vs. {game.color !== "white" ? game.name : (game.opponentUsers?.[0].name ?? t("Unknown Opponent"))}</div>
                    <div style={{marginTop: '8px'}}>
                      {t("Result")}: <span style={{fontWeight: 'bold'}}>{t(game.gameGeneralStatus + "_1")}</span>
                    </div>
                    <div style={{fontWeight: 'regular'}}>{t(game.gameStatus)}</div>
                  </div>
                  <button 
                    onClick={() => handleInfoClick(game)}
                    style={styles.actionButton}
                  >
                    {t("More Info")}
                  </button>
                </div>
              </div>
            ))}
          </div>
          
          {/* Improved Pagination Controls */}
          <div style={styles.pagination}>
            <div>
              <p style={styles.paginationText}>
                {t("Showing")} {totalItems > 0 ? startIndex : 0} {t("to")} {endIndex} {t("of")} {totalItems} {t("results")}
              </p>
            </div>
            
            <div style={styles.paginationControls}>
              {/* Jump to first page */}
              <button
                onClick={() => jumpToPage(1)}
                //disabled={currentPage === 1 || totalPages === 0}
                style={{
                  ...styles.paginationButton,
                  ...((currentPage === 1 || totalPages === 0) ? styles.paginationButtonDisabled : {})
                }}
                aria-label="First page"
              >
                &laquo;&laquo;
              </button>
              
              {/* Previous page */}
              <button
                onClick={() => jumpToPage(currentPage - 1)}
                //disabled={currentPage === 1 || totalPages === 0}
                style={{
                  ...styles.paginationButton,
                  ...((currentPage === 1 || totalPages === 0) ? styles.paginationButtonDisabled : {})
                }}
                aria-label="Previous page"
              >
                &laquo;
              </button>
              
              {/* Page numbers */}
              {getPageNumbers().map((pageNum, index) => (
                pageNum === '...' ? (
                  <span key={`ellipsis-${index}`} style={{ margin: '0 4px' }}>...</span>
                ) : (
                  <button
                    key={pageNum}
                    onClick={() => jumpToPage(pageNum)}
                    style={{
                      ...styles.paginationButton,
                      ...(Number(currentPage) === Number(pageNum) ? styles.paginationButtonActive : {}) // Ensuring type consistency
                    }}
                  >
                    {pageNum}
                  </button>
                )
              ))}
                            
              {/* Next page */}
              <button
                onClick={() => jumpToPage(currentPage + 1)}
                disabled={currentPage >= totalPages || totalPages === 0}
                style={{
                  ...styles.paginationButton,
                  ...((currentPage >= totalPages || totalPages === 0) ? styles.paginationButtonDisabled : {})
                }}
                aria-label="Next page"
              >
                &raquo;
              </button>
              
              {/* Jump to last page */}
              <button
                onClick={() => jumpToPage(totalPages)}
                disabled={currentPage >= totalPages || totalPages === 0}
                style={{
                  ...styles.paginationButton,
                  ...((currentPage >= totalPages || totalPages === 0) ? styles.paginationButtonDisabled : {})
                }}
                aria-label="Last page"
              >
                &raquo;&raquo;
              </button>
              
              {/* Direct page input for larger datasets */}
              {totalPages > 7 && (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                  <span style={{ fontSize: '0.875rem', marginRight: '4px' }}>Go to:</span>
                  <input
                    type="number"
                    min="1"
                    max={totalPages}
                    value={currentPage}
                    onChange={handlePageInputChange}
                    onBlur={(e) => {
                      const value = parseInt(e.target.value);
                      if (!isNaN(value) && value >= 1 && value <= totalPages) {
                        jumpToPage(value);
                      } else {
                        e.target.value = currentPage;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const value = parseInt(e.target.value);
                        if (!isNaN(value) && value >= 1 && value <= totalPages) {
                          jumpToPage(value);
                        } else {
                          e.target.value = currentPage;
                        }
                      }
                    }}
                    style={{
                      width: '50px',
                      padding: '4px 8px',
                      textAlign: 'center',
                      border: '1px solid #d1d5db',
                      borderRadius: '4px',
                      fontSize: '0.875rem'
                    }}
                  />
                  <span style={styles.pageInfo}>of {totalPages}</span>
                </div>
              )}
            </div>
          </div>
          
          {/* Items per page selector and Pagination Mode Switch */}
          <div style={styles.itemsPerPageContainer}>
            <div style={styles.paginationModeSwitch}>
              
              <label style={styles.switchInput}>
               
                
              </label>
             
            </div>
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="itemsPerPage" style={styles.itemsPerPageLabel}>
                {t("Items per page:")}
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                style={styles.itemsPerPageSelect}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserGames;