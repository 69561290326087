import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import PurchaseCoins from "./PurchaseCoins";
import PurchaseCoinsFacebook from "./PurchaseCoinsFacebook";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Replace with your actual Stripe public key
const stripePromise = loadStripe("your-stripe-public-key");

const NotEnoughCoinsDialog = ({
  open,
  onClose,
  onWatchAd,
  neededCoins,
  actualCoins,
  buy = false,
  userData,
}) => {
  const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);
  const { t } = useTranslation();
  const [title, setTitle] = useState(t("Not Enough Coins"));

  useEffect(() => {
    if (buy) {
      setTitle(t("Buy Coins"));
      setShowPurchaseOptions(true);
    }
  }, [buy, t]);

  const handleBuyCoinsClick = () => {
    setShowPurchaseOptions(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ zIndex: 3500 }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "20px",
          bgcolor: "gold",
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent>
        {/* Info block only if user lacks coins */}
        {!buy && (
          <>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", marginBottom: "10px" }}
            >
              {t("You don’t have enough coins to play the selected game")}
            </Typography>
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "red", fontWeight: "bold" }}
            >
              {t("Needed")}: {neededCoins} | {t("You Have")}: {actualCoins}
            </Typography>
          </>
        )}

        {/* Show action buttons or purchase component */}
        {!showPurchaseOptions ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleBuyCoinsClick}
              sx={{ fontWeight: "bold", padding: "10px 20px" }}
            >
              {t("Buy Coins")}
            </Button>
            {!buy && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={onWatchAd}
                sx={{ fontWeight: "bold", padding: "10px 20px" }}
              >
                {t("Watch Ad")}
              </Button>
            )}
          </Box>
        ) : (
          // Conditionally render Facebook or Stripe purchase components
          <>
            {window.FB_USER_DATA ? (
              <PurchaseCoinsFacebook
                onClose={() => {
                  setShowPurchaseOptions(false);
                  onClose();
                }}
                userData={userData}
              />
            ) : (
              <Elements stripe={stripePromise}>
                <PurchaseCoins
                  onClose={() => {
                    setShowPurchaseOptions(false);
                    onClose();
                  }}
                  enablePurchase={true}
                  userData={userData}
                />
              </Elements>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose} color="error" variant="text">
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotEnoughCoinsDialog;
